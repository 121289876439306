import React, { useEffect, useRef, useState } from "react";
import { Tooltip, Typography, TextField, CircularProgress } from "@mui/material";
import loader from "../../assets/ui_img/loader.svg";
import axiosInstance from "../../api/axios";
import { toast } from "react-toastify";
import {
  PlayCircleOutlineOutlined,
  StopCircleOutlined,
  InfoOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { GoLinkExternal } from "react-icons/go";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoTimeOutline } from "react-icons/io5"; // For Edit Auto-Stop icon
import { ClipLoader } from "react-spinners"; // For loading spinner

const InstanceTab = ({
  openTab,
  onTabChange,
  convertToLocalTime,
  handleRefreshToken,
}) => {
  const [myinstance, setMyinstance] = useState([]);
  const [loading, setLoading] = useState(false);
  const [launchingInstance, setLaunchingInstance] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteIns, setDeleteIns] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [sshKey, setSshKey] = useState("");
  const [selectedEleId, setSelectedEleId] = useState(null);
  const [showAutoStopModal, setShowAutoStopModal] = useState(false);
  const [showEditAutoStopModal, setShowEditAutoStopModal] = useState(false);
  const [autoStopTime, setAutoStopTime] = useState(60);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [showNotebookToken, setShowNotebookToken] = useState(false); 
  const sshKeyRef = useRef(null);
  const LoaderComponent = () => <ClipLoader color={"#000"} loading={true} size={24} />;

  // Load instances
  const loadInstance = async () => {
    const url = `/manage/user_instances`;
    try {
      const response = await axiosInstance.get(url);
      const data = response.data;
      setMyinstance(data.instances);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          loadInstance();
        }
      } else {
        console.error("Error loading instances:", error);
      }
    }
  };

  useEffect(() => {
    loadInstance();
    const interval = setInterval(() => {
      loadInstance();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  // Handle opening Jupyter Notebook or SSH key
  const handleOpen = async (ele) => {
    setSelectedEleId(ele.plan_name);
    if (ele.user_selection === "ssh") {
      setSshKey(ele.jupyter_token);
    } else {
      setSshKey("");
      window.open(ele.jupyter_token, "_blank");
    }
  };

  // Handle click outside to close SSH key block
  useEffect(() => {
    if (sshKey) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sshKey]);

  const handleClickOutside = (event) => {
    if (sshKeyRef.current && !sshKeyRef.current.contains(event.target)) {
      setSshKey("");
    }
  };

  // Delete instance
  const deleteInstance = async (ele) => {
    setLoading(true);
    sessionStorage.clear();
    const requestData = {
      plan_name: ele.plan_name,
    };
    try {
      const response = await axiosInstance.post(`/resources/delete`, requestData);
      toast(response?.data?.message);
      // const cpuid = response.data.instance;
      // await axiosInstance.post(`/manage/InActivatePlan`, { plan: cpuid });
      loadInstance();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          deleteInstance(ele);
        }
      } else {
        console.error("Error deleting instance:", error);
        toast(error.response?.data?.message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  // Add the new handler
  const handleEditAutoStop = async () => {
    if (!autoStopTime || isNaN(autoStopTime) || autoStopTime <= 0) {
      toast.error("Please enter a valid auto-stop duration in hours.");
      return;
    }

    setLoading(true);
    try {
      console.log(autoStopTime,"aaaaaaaaaaaaaaaaaaaa")
      const response = await axiosInstance.post("/resources/edit_stop_resources", {
        plan_name: selectedInstance.plan_name,
        auto_stop:autoStopTime,
        // auto_stop: parseInt(autoStop, 60),
      });
      console.log(response,"aaaaaaaaaaaaaaaaaaaa")
      toast.success("Auto-stop updated successfully!");
      setShowEditAutoStopModal(false);
      loadInstance();// Refresh the models list
    } catch (error) {
      console.error("Error updating auto-stop:", error);
      toast.error("Failed to update auto-stop.");
    } finally {
      setLoading(false);
    }
  };

  // Start instance with auto-stop
  const handleStart = async (ele) => {
    setSelectedInstance(ele);
    setAutoStopTime(ele.remaining_time || 60); // Set default auto-stop time
    setShowAutoStopModal(true); // Open auto-stop modal
  };

  // Handle auto-stop submission
  const handleAutoStopSubmit = async () => {
    if (!autoStopTime || isNaN(autoStopTime) || autoStopTime <= 0) {
      toast.error("Please enter a valid auto-stop duration in minutes.");
      return;
    }

    setLoading(true);
    try {
      const response = await axiosInstance.post("/resources/start_resources", {
        plan_name: selectedInstance.plan_name,
        auto_stop: autoStopTime,
      });
      toast.success(response.data.message);
      setShowAutoStopModal(false);

      // Start polling for deployment status
      const intervalId = setInterval(async () => {
        try {
          const statusResponse = await axiosInstance.get(
            `/resources/track-deployment-status?plan_name=${selectedInstance.plan_name}`
          );
          const status = statusResponse.data.status;

          // Stop polling if the instance is running
          if (status === "Complete") {
            clearInterval(intervalId);
            loadInstance(); // Refresh the instances list
            toast(`${selectedInstance.plan_name} is now running!`);
          }
        } catch (error) {
          console.error("Error fetching deployment status:", error);
        }
      }, 5000); // Poll every 5 seconds
    } catch (error) {
      console.error("Error starting instance:", error);
      toast.error("Failed to start instance.");
    } finally {
      setLoading(false);
    }
  };

  // Stop instance
  const handleStop = async (ele) => {
    setLaunchingInstance(true);
    try {
      const response = await axiosInstance.post(`/resources/stop`, {
        plan_name: ele.plan_name,
      });
      toast(response.data.message);
      loadInstance();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          handleStop(ele);
        }
      } else {
        console.error("Error stopping instance:", error);
        toast(error.response?.data?.message || "Failed to stop instance");
      }
    } finally {
      setLaunchingInstance(false);
    }
  };

  // Pagination
  const totalPages = Math.ceil(myinstance.length / pageSize);
  const currentInstances = myinstance.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  return (
    <>
      <div className={openTab === 1 ? "block" : "hidden"} id="link1">
        <table className="w-full rounded-lg overflow-hidden shadow-lg">
          {/* <thead className="bg-gradient-to-r from-blue-500 to-blue-600"> */}
          <thead className="bg-table-header-bg-color">
            {/* <tr>
              <th className="py-4 px-6 text-sm font-semibold text-white text-left">Instance Type</th>
              <th className="py-4 px-6 text-sm font-semibold text-white text-left">Type</th>
              <th className="py-4 px-6 text-sm font-semibold text-white text-left">Inference</th>
              <th className="py-4 px-6 text-sm font-semibold text-white text-left">Created Date</th>
              <th className="py-4 px-6 text-sm font-semibold text-white text-left">Status</th>
              <th className="py-4 px-6 text-sm font-semibold text-white text-left">Action</th>
            </tr> */}
            <tr>
              <th className="py-4 px-6 text-sm font-semibold  text-left">Instance Type</th>
              <th className="py-4 px-6 text-sm font-semibold  text-left">Type</th>
              <th className="py-4 px-6 text-sm font-semibold  text-left">Interface</th>
              <th className="py-4 px-6 text-sm font-semibold  text-left">Created Date</th>
              <th className="py-4 px-6 text-sm font-semibold  text-left">Status</th>
              <th className="py-4 px-6 text-sm font-semibold  text-left">Action</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentInstances?.map((ele, i) => (
              <tr key={ele.instance_id} className="hover:bg-gray-50 transition-colors">
                <td className="py-4 px-6 text-sm font-medium text-gray-900">{ele.plan_name}</td>
                <td className="py-4 px-6 text-sm text-gray-700">{ele.instance_type.toUpperCase()}</td>
                <td className="py-4 px-6 text-sm text-gray-700">{ele.user_selection.toUpperCase()}</td>
                <td className="py-4 px-6 text-sm text-gray-700">{convertToLocalTime(ele.instance_launch_time)}</td>
                <td className="py-4 px-6 text-sm">
                  <span
                    className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                      ele.status === "intializing"
                        ? "bg-green-100 text-green-700"
                        : ele.status === "terminated"
                        ? "bg-red-100 text-red-700"
                        : ele.status === "running"
                        ? "bg-blue-100 text-blue-700"
                        : "bg-gray-100 text-gray-700"
                    }`}
                  >
                    {ele.status}
                  </span>
                </td>
                <td className="py-4 px-6 text-sm text-gray-700 flex items-center space-x-4">
                  {ele.status === "running" ? (
                    <>
                      <Tooltip title="Stop" placement="top" arrow>
                        <button
                          className="text-red-500 hover:text-red-700 transition-colors"
                          onClick={() => handleStop(ele)}
                        >
                          <StopCircleOutlined className="h-5 w-5" />
                        </button>
                      </Tooltip>
                      <Tooltip title={ele.user_selection === "ssh" ? "View SSH Key" : "Open Jupyter Notebook"} placement="top" arrow>
                        <button
                          className="text-blue-500 hover:text-blue-700 transition-colors"
                          onClick={() => handleOpen(ele)}
                        >
                          <GoLinkExternal className="h-5 w-5" />
                        </button>
                      </Tooltip>
                      {selectedEleId === ele.plan_name && ele?.user_selection === "ssh" && sshKey &&  (
                      <div
                      ref={sshKeyRef}
                      style={{
                        marginTop: "10px",
                        padding: "10px",
                        border: "1px solid #ddd",
                        background: "#f9f9f9",
                        position: "relative",
                      }}
                    >
                        <h4>SSH Key:</h4>
                        <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{sshKey}</pre>
                      </div>
                    )}
                      <Tooltip title="Edit Auto-Stop" placement="top" arrow>
                        <button
                          className="text-purple-500 hover:text-purple-700 transition-colors"
                          onClick={() => {
                            setSelectedInstance(ele);
                            setAutoStopTime(ele.auto_stop_remaining || 60);
                            setShowEditAutoStopModal(true);
                          }}
                        >
                          <IoTimeOutline className="h-5 w-5" />
                        </button>
                      </Tooltip>
                      <Tooltip title="Delete" placement="top" arrow>
                        <button
                          className="text-gray-500 hover:text-gray-700 transition-colors"
                          onClick={() => {
                            setShowConfirmationModal(true);
                            setDeleteIns(ele);
                          }}
                        >
                          <FaRegTrashAlt className="h-5 w-5" />
                        </button>
                      </Tooltip>
                      <Tooltip title="Details" placement="top" arrow>
                        <button
                          className="text-green-500 hover:text-green-700 transition-colors"
                          onClick={() => {
                            setSelectedDetails(ele);
                            setShowDetailsModal(true);
                          }}
                        >
                          <InfoOutlined className="h-5 w-5" />
                        </button>
                      </Tooltip>
                    </>
                  ) : ele.status === "stopped" ? (
                    <>
                      <Tooltip title="Start" placement="top" arrow>
                        <button
                          className="text-green-500 hover:text-green-700 transition-colors"
                          onClick={() => handleStart(ele)}
                        >
                          <PlayCircleOutlineOutlined className="h-5 w-5" />
                        </button>
                      </Tooltip>
                      <Tooltip title="Delete" placement="top" arrow>
                        <button
                          className="text-gray-500 hover:text-gray-700 transition-colors"
                          onClick={() => {
                            setShowConfirmationModal(true);
                            setDeleteIns(ele);
                          }}
                        >
                          <FaRegTrashAlt className="h-5 w-5" />
                        </button>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Unknown" placement="top" arrow>
                        <button className="text-gray-400 cursor-not-allowed">
                          <InfoOutlined className="h-5 w-5" />
                        </button>
                      </Tooltip>
                      <Tooltip title="Deleted" placement="top" arrow>
                        <button className="text-gray-400 cursor-not-allowed">
                          <FaRegTrashAlt className="h-5 w-5" />
                        </button>
                      </Tooltip>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Auto-Stop Modal */}
        {showAutoStopModal && selectedInstance && (
          <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Set Auto-Stop Time</h3>
                  </div>
                  <div className="mt-5">
                    {/* <Typography variant="body1" className="mb-4">
                      Approx {selectedInstance.auto_stop_remaining|| "N/A"} minutes left before the instance stops.
                    </Typography>
                    <br></br> */}
                    <TextField
                      label="Auto Stop (in hours)"
                      name="auto_stop"
                      type="number"
                      value={autoStopTime}
                      onChange={(e) => setAutoStopTime(e.target.value)}
                      className="w-full"
                      inputProps={{ min: 1 }}
                      helperText="💡 Set the number of hours after which the model will automatically stop. This helps manage costs and resources efficiently."
                    />
                  </div>
                  <div className="mt-5 sm:mt-6 flex justify-center">
                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => setShowAutoStopModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="general-button ml-5"
                      onClick={handleAutoStopSubmit}
                      disabled={loading}
                    >
                      {loading ? <ClipLoader size={20} color="#000" /> : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Confirmation Modal */}
        {showConfirmationModal && deleteIns  && (
                      <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div
                            className="fixed inset-0 transition-opacity"
                            aria-hidden="true"
                          >
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                          >
                            &#8203;
                          </span>
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                              <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                  Are you sure you want to delete?
                                </h3>
                              </div>
                              <div className="mt-5 sm:mt-6 flex justify-center">
                                <button
                                  type="button"
                                  className="cancel-button"
                                  onClick={() => {
                                    setShowConfirmationModal(false);
                                    deleteInstance(deleteIns);
                                  }}
                                >
                                  Delete
                                </button>
                                <button
                                  type="button"
                                  className="general-button ml-5"
                                  onClick={() =>
                                    setShowConfirmationModal(false)
                                  }
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

        {showEditAutoStopModal && (
                <div className="fixed z-40 inset-0 overflow-y-auto">
                  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                      &#8203;
                    </span>
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                      <div>
                        <div className="mt-3 text-center sm:mt-5">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">Edit Auto-Stop</h3>
                        </div>
                        <div className="mt-5">
                          <Typography variant="body1" className="mb-4">
                            Approx {selectedInstance?.auto_stop_remaining || 60} hours left before the model stops.
                            {/* Approx {selectedModel} hours left before the model stops. */}
                          </Typography>
                          <br></br>
                          <TextField
                            label="Auto Stop (in hours)"
                            name="auto_stop"
                            type="number"
                            value={autoStopTime}
                            onChange={(e) => setAutoStopTime(e.target.value)}
                            className="border p-2 rounded-md text-sm w-full"
                            inputProps={{ min: 1 }}
                            helperText="💡 Set the number of hours after which the model will automatically stop. This helps manage costs and resources efficiently."
                          />
                        </div>
                        <div className="mt-5 sm:mt-6 flex justify-center">
                          <button
                            type="button"
                            className="cancel-button"
                            onClick={() => setShowEditAutoStopModal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="general-button ml-5"
                            onClick={handleEditAutoStop}
                            disabled={loading}
                          >
                            {loading ? <LoaderComponent /> : "Update Auto-Stop"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
        {/* Details Modal */}
        {showDetailsModal && selectedDetails && (
          <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Instance Details</h3>
                  </div>
                  <div className="mt-5">
                    <ul className="space-y-2">
                      <li><strong>Hourly Price:</strong> {selectedDetails.hourly_price}</li>
                      <li><strong>Auto Stop:</strong> {selectedDetails.auto_stop ? "Yes" : "No"}</li>
                      <li><strong>Auto Stop Remaining:</strong> {selectedDetails.auto_stop_remaining}</li>
                      <li>
                        <strong>Notebook Token:</strong>
                        <div className="flex items-center">
                          <input
                            type={showNotebookToken ? "text" : "password"}
                            value={selectedDetails.notebook_token}
                            readOnly
                            className="border p-1 rounded-md"
                          />
                          <button
                            className="ml-2 text-blue-500 hover:text-blue-700"
                            onClick={() => setShowNotebookToken(!showNotebookToken)}
                          >
                            {showNotebookToken ? <VisibilityOff /> : <Visibility />}
                          </button>
                        </div>
                      </li>
                      {/* <li><strong>Remaining Time:</strong> {selectedDetails.remaining_time}</li>
                      <li><strong>Start Time:</strong> {convert */}
                      </ul>
                    </div>
                  </div>
                  {/* Close button */}
                  <div className="mt-5 sm:mt-6 text-center">
                    <button
                      onClick={() => setShowDetailsModal(false)}
                      className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* Pagination */}
        <div className="bg-white flex justify-end items-center border-t border-gray-100 p-4 space-x-3">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium">Rows Per Page:</span>
            <select
              className="border rounded px-2 py-1 text-sm bg-white"
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-sm">
              {currentInstances.length > 0
                ? `${(currentPage - 1) * pageSize + 1} - ${Math.min(
                    currentPage * pageSize,
                    myinstance.length
                  )} of ${myinstance.length}`
                : "0"}
            </span>
            <button
              className={`px-3 py-1 border rounded ${
                currentPage === 1 && "opacity-50 cursor-not-allowed"
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <span className="text-sm">{currentPage}</span>
            <button
              className={`px-3 py-1 border rounded ${
                currentPage === totalPages && "opacity-50 cursor-not-allowed"
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>

        {loading && (
          <div>
            <div className="absolute inset-0 flex flex-col items-center justify-center z-[200]">
              <img src={loader} alt="Loading..." className="spinner" />
              <p className="font-medium text-lg text-white">Please wait...</p>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default InstanceTab;