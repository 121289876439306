import { TextField } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { ImSpinner3 } from "react-icons/im";
import Markdown from "react-markdown";
import { toast } from "react-toastify";
import {
  getInstanceStatus,
  getImageToText,
  getStatusLight,
  getInstanceStatusStopper,
} from "../../../services/api";
import { Context } from "../../../Context";
import { AiOutlineClose } from "react-icons/ai";
import Slider from "../../Slider";
import axiosInstance from '../../../api/axios';
import AddCreditModal from "../../AddCreditModal";

const ImageToText = ({ endpoint, isActive, ins_id, modelName,credit }) => {
  const { setModelStatusLight } = useContext(Context);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [fileName, setFileName] = useState("");
  const [QueryText, setQueryText] = useState("");

  const [loading, setLoading] = useState(false);
  const [initialising, setInitialising] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [tokens, setTokens] = useState(500);
  const [temp, setTemp] = useState(0.5);
  const [top_p, setTop_p] = useState(1);
  const [rep_p, setRep_p] = useState(1);
  const fileInputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const checkInstance = async () => {
    setApiResponse("");
    if (!uploadedImage) {
      toast.warning("Please Provide Image!");
      return;
    }
    setInitialising(true);
    setModelStatusLight("initialising");

    try {
        // Step 1: Check for available credits (GET request)
        const rescheck = await axiosInstance.get(`payments/availableCredit/?model-name=${modelName}`);

        if (rescheck.data.data.status) {
            // Step 2: Credit is available, proceed to deduct it (POST request)
            const deductCredit = await axiosInstance.post('payments/availableCredit/', {
                model_name: modelName,
                add: false,
                sub: true,
                amount: 0
            });

            if (deductCredit.data.data.status) {
                // Proceed with the instance status check since credit deduction was successful
                const res = await getInstanceStatus({ instance_id: ins_id });
                console.log(res);

                if (res.status === 200) {
                    setLoading(true);
                    setInitialising(false);

                    try {
                        const responseLight = await getStatusLight({ instance_id: ins_id });
                        setModelStatusLight(responseLight.data.status);
                    } catch (error) {
                        console.log(error.message);
                        await refundCredit(); // Refund credit if something goes wrong
                    }

                    // Trigger handleClick for text generation
                    handleClick(deductCredit);
                }
            } else {
                // Failed to deduct credit, stop further execution
                toast.error(deductCredit.data.message);
                setLoading(false);
                setInitialising(false);
                return false; // Stop further execution
            }
        } else {
            // If no credit, show warning and stop the process
            toast.error(rescheck.data.message);
        setIsOpen(true); // Open AddCreditModal when no credit
            setLoading(false);
            setInitialising(false);
            return false; // Stop further execution
        }
    } catch (error) {
        toast.error("Something Went Wrong,Please Try Again!");
        setLoading(false);
        setInitialising(false);
    }
};

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file && file.type.startsWith("image/")) {
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setUploadedImage(file);
          setFileName(file.name);
        };
        reader.readAsDataURL(file);
      } else {
        toast.error("File size exceeds 5MB limit.");
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file.size <= maxSize) {
      setUploadedImage(file);
      setFileName(file.name);
    } else {
      toast.error("File size exceeds 5MB limit.");
    }
  };

  const handleBoxClick = () => {
    fileInputRef.current.click();
  };

  const handleClick = async (deductCredit) => {
    setApiResponse("");
    if (!uploadedImage) {
      toast.warning("Please Provide Image!");
      return;
    }

    const formData = new FormData();
    formData.append("image_path", uploadedImage);
    if (QueryText) {
      formData.append("text_input", QueryText);
    }

    try {
      const response = await getImageToText(endpoint, formData);
      setApiResponse(response.data.response);
      toast.success(deductCredit.data.message);
      if(deductCredit.data.message === "You have used 1 out of 1 free inferencing credits.") {
        infInstanceStatus()
      }
    } catch (error) {
      toast.error(error);
      console.error("Error processing output:", error);
      await refundCredit(); // Refund credit on error
    } finally {
      setLoading(false);
      const res = await getInstanceStatusStopper({ instance_id: ins_id });
      console.log(res);
    }
  };

     
    // Function to refund credit
    const refundCredit = async () => {
      try {
          await axiosInstance.post('payments/availableCredit/', {
              model_name: modelName,
              add: true,
              sub: false,
              amount: 0
          });
          console.log('Credit refunded successfully');
      } catch (error) {
          console.log('Failed to refund credit:', error.message);
      }
  };

  const infInstanceStatus = async () => {
    try {
      await axiosInstance.post("payments/updateStatus/", {
        all_free_credits_used: true
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  const handleRemoveImage = () => {
    setUploadedImage(null);
    fileInputRef.current.value = null;
    setFileName("");
  };

  return (
    <div className="flex w-full h-full">
      {/* User Interaction Section */}
      <div className="w-1/2 h-full px-8">
        <p className="text-[16px] font-[600] mb-8">Generate Response</p>
        <p className='text-[15px] font-[500] -mt-6 mb-6'>Running this model will deduct <span className='text-[#008B16] font-[800]'>${credit}</span> / request from your balance. </p>
        {/* Drag and Drop Section */}
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={handleBoxClick}
          className="mb-6 flex justify-center items-center h-[150px] w-full bg-white border-dashed border-2 border-gray-400 rounded-md cursor-pointer relative"
        >
          {uploadedImage ? (
            <div className="w-full h-full flex justify-center items-center">
              <div className="relative">
                <img
                  src={URL.createObjectURL(uploadedImage)}
                  alt="Uploaded preview"
                  className="h-[120px] max-w-[120px] object-cover rounded-md"
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveImage();
                  }}
                  className="absolute top-0 right-0 bg-white rounded-full p-1 shadow-sm hover:bg-gray-200"
                >
                  <AiOutlineClose className="w-4 h-4" />
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center hover:text-blue-500">
              <span>Click to upload or Drag & Drop</span>
            </div>
          )}
          <input
            type="file"
            id="fileInput"
            accept="image/png, image/jpeg, image/jpg"
            className="hidden"
            onChange={handleFileInputChange}
            ref={fileInputRef}
          />
        </div>

        {/* Display uploaded file name */}
        {fileName && (
          <p className="text-sm text-gray-600 mb-10">
            <b>Uploaded file:</b>
            {"\u00A0\u00A0" + fileName}
          </p>
        )}

        {/* User Query Section */}
        <TextField
          className="w-full"
          sx={{ marginTop: "20px" }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey) {
              e.preventDefault();
              setQueryText((prevValue) => prevValue + "\n");
            } else if (e.key === "Enter") {
              e.preventDefault();
              setQueryText(e.target.value);
              checkInstance();
            }
          }}
          id="outlined-multiline-static"
          label="Image Query"
          multiline
          rows={2}
          value={QueryText}
          onChange={(e) => setQueryText(e.target.value)}
        />

        <Slider
          label={"Max New Tokens"}
          value={tokens}
          setValue={setTokens}
          min={100}
          max={2000}
          step={1}
          text={
            "The maximum numbers of tokens to generate, ignoring the number of tokens in the prompt.(One token is roughly 4 characters for standard English text)"
          }
        />
        <Slider
          label={"Temperature"}
          value={temp}
          setValue={setTemp}
          min={0}
          max={2}
          step={0.01}
          text={
            "Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive."
          }
        />
        <Slider
          label={"Top_P"}
          value={top_p}
          setValue={setTop_p}
          min={0}
          max={1}
          step={0.01}
          text={"Only tokens within the top top_p% probability are considered."}
        />
        <Slider
          label={"Repetition Penalty"}
          value={rep_p}
          setValue={setRep_p}
          min={1}
          max={2}
          step={0.01}
          text={"The parameter for repetition penalty. 1.0 means no penalty."}
        />

        {loading ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Running
          </button>
        ) : initialising ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Initializing
          </button>
        ) : (
          <button
            disabled={!isActive}
            className={`${
              isActive ? "classic-button" : "disabled-button"
            } w-[136px] h-[52px] mx-auto mb-5`}
            onClick={() => checkInstance()}
          >
            Run
          </button>
        )}
        {initialising && (
          <p className="text-gen-color mt-4 text-[14px] font-[600]">
            The Model instance is initializing, This will take 1 to 2 mins. Once
            the instance is active we will process your request
          </p>
        )}
      </div>
      {/* Response Section */}
      <div className="w-1/2 min-h-[538px] p-8 border-l-2 ">
        <p className="text-[16px] font-[600] mb-8">Response</p>
        {apiResponse && (
          <Markdown className="text-base text-gray-700 border border-gray-300 resize-y w-full p-3 bg-white rounded-lg scrollable-markdown scrollbar-width-half">
            {apiResponse}
          </Markdown>
        )}
      </div>
      <AddCreditModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default ImageToText;
