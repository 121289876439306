import React, { useState, useRef, useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import axiosInstance from "../../../api/axios";
import AddCreditModal from "../../AddCreditModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Slider } from "@mui/material";
import nvidiaLogo from "../../../assets/nvdia logo.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";

const AddHuggingModal = ({ isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [showToken, setShowToken] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isCreditOpen, setIsCreditOpen] = useState(false);
  const [modelSelection, setModelSelection] = useState({
    huggingfacemodel: "google/gemma-2-2b-it",
    model_name: "meta-lamma",
    huggingFaceToken: "hf_JNxxxxxxxxxxxxxxxxxxxx",
    model_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  });
  const [autoStop, setAutoStop] = useState(60);
  const [selectedGPU, setSelectedGPU] = useState("");
  const [gpuOptions, setGpuOptions] = useState([]);
  const [gpuCount, setGpuCount] = useState(1);
  const [selectedGPUPrice, setSelectedGPUPrice] = useState(0);
  const [selectedQubridName, setSelectedQubridName] = useState("");
  const [gpuCountOptions, setGpuCountOptions] = useState([]);

  const steps = [
    { label: "Step 01", description: "Basic Info" },
    { label: "Step 02", description: "Model Selection" },
    { label: "Step 03", description: "GPU Selection" },
  ];

  const navigate = useNavigate();

  // Handle GPU selection
  const handleSelectGPU = (gpuName, gpuDetails) => {
    setSelectedGPU(gpuName);
    setGpuCountOptions(gpuDetails); // Set available GPU counts and prices
    setGpuCount(gpuDetails[0]?.gpu_count || 1); // Default to the first available count
    setSelectedGPUPrice(gpuDetails[0]?.price || 0); // Default to the first available price
    setSelectedQubridName(gpuDetails[0]?.qubrid_name || ""); // Default to the first qubrid_name
  };

  // Handle GPU count slider change
  const handleSliderChange = (event, newValue) => {
    const selectedOption = gpuCountOptions.find((option) => option.gpu_count === newValue);
    if (selectedOption) {
      setGpuCount(newValue);
      setSelectedGPUPrice(selectedOption.price);
      setSelectedQubridName(selectedOption.qubrid_name);
    }
  };

  // Calculate total price based on GPU count and price
  const getTotalPrice = () => {
    return (selectedGPUPrice).toFixed(2);
  };

  //  // Handle auto_stop input change
  //  const handleAutoStopChange = (e) => {
  //   const value = parseInt(e.target.value, 10);
  //   if (value >= 1 && value <= 10) {
  //     setAutoStop(value);
  //   }
  // };

  // Handle auto_stop input change
  const handleAutoStopChange = (e) => {
    const value = e.target.value;

    // Ensure the value is a positive integer
    if (/^\d*$/.test(value) && value >= 0) {
      setAutoStop(value === "" ? "" : parseInt(value, 10));
    }
  };

  // Handle model deployment
  const handleTune = async () => {
    setLoading(true);
    const payload = {
      hugging_face_model_id: modelSelection.huggingfacemodel,
      model_name: modelSelection.model_name,
      qubrid_name: selectedQubridName, // Include qubrid_name in the payload
      gpu_count: gpuCount, // Include GPU count in the payload
      price: selectedGPUPrice,
      description: modelSelection.model_description,
      hugging_face_token: modelSelection.huggingFaceToken,
    };

    try {
      const response = await axiosInstance.post(`/resources/deploy_hugging_models`, payload);
      toast(response.data.message);

      if (response.data.message === "Insufficient credits.") {
        setIsCreditOpen(true);
      }

      if (response.data.success) {
        navigate("/modelStudio?tab=huggingFaceModels"); // Redirect to a success page or handle accordingly
      }
    } catch (error) {
      toast(error.response?.data?.message || "Error deploying model");
      console.error("Error deploying model:", error);
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  // Validate Hugging Face model and token
  const validateModelAndToken = async () => {
    setErrorMessage(null);
    setLoading(true);

    try {
      const response = await axiosInstance.post("/nocode/huggingface_check", {
        model_id: modelSelection.huggingfacemodel,
        huggingface_token: modelSelection.huggingFaceToken,
      });

      if (response.data.success) {
        const gpuPlansResponse = await axiosInstance.get("/nocode/gpu_plans");

        if (gpuPlansResponse.data.success) {
          const gpuData = gpuPlansResponse.data.data;
          const gpuOptionsArray = Object.keys(gpuData).map((gpuName) => ({
            name: gpuName,
            details: gpuData[gpuName], // Store all GPU details (counts, prices, qubrid_names)
          }));

          setGpuOptions(gpuOptionsArray);
          setActiveStep(2); // Move to GPU selection step
        } else {
          showErrorToast("Failed to fetch GPU plans.");
        }
      } else {
        // showErrorToast("Invalid Hugging Face model or token.");
        showErrorToast(response.data.message);
      }
    } catch (error) {
      showErrorToast(error.response.data.message);
      // showErrorToast("Invalid Hugging Face model or token.");
      // console.error("Error validating model and token:", error);
    } finally {
      setLoading(false);
    }
  };

  // Show error toast
  const showErrorToast = (message) => {
    setErrorMessage(message);
    toast.dismiss();
    toast(message);
  };

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  return (
    <>
      {isOpen && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mb-10">
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
          <div className="relative mx-auto my-auto max-w-3xl w-full z-50">
            <div className="m-auto bg-white rounded-xl pt-5 shadow-xl transform transition-all modal-main-component">
              <div>
                {/* Header Section */}
        <div className="w-full px-6 py-4 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-xl font-semibold text-gray-800">Deploy Hugging Face Model</h2>
              <p className="text-sm text-gray-500 mt-1">
                Using a direct API and an interactive UI like Rag UI, you can now deploy and interact with models without requiring any AI expertise.
              </p>
            </div>
            <button
              onClick={() => setIsOpen(false)}
              className="p-2 rounded-lg hover:bg-gray-100 transition-colors duration-200"
            >
              <IoCloseSharp className="text-2xl text-gray-600" />
            </button>
          </div>
        </div>
                {/* <div className="w-full px-5 pb-5 flex justify-between items-center">
                  <span className="font-semibold text-[18px]">Deploy Hugging Face Model</span>
                  <span className="text-sm text-gray-500">Using direct API and access through an interactive UI, you can now deploy and communicate with models without needing any AI skills.</span>
                  <IoCloseSharp className="text-2xl cursor-pointer" onClick={() => setIsOpen(false)} />
                </div> */}

                <hr className="w-full h-full" />
                <div className="flex max-w-[935px] max-h-[600px] overflow-scroll modal-main-content">
                  <div className="w-3/12 h-inherit min-h-[360px] border-r flex flex-col overflow-auto">
                    <div className="flex items-center pt-4 ml-10">
                      <Box sx={{ maxWidth: 400 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                          {steps.map((step) => (
                            <Step key={step.label}>
                              <StepLabel>{step.label}</StepLabel>
                              <StepContent>
                                <Typography>{step.description}</Typography>
                              </StepContent>
                            </Step>
                          ))}
                        </Stepper>
                      </Box>
                    </div>
                  </div>

                  {activeStep === 0 && (
                    <div className="w-9/12 h-full flex flex-col p-6">
                      <p className="text-justify text-[18px] font-[600]">Basic Info</p>
                      <div className="my-10 text-left sm:mt-5 space-y-6">
                        <TextField
                          name="model_name"
                          value={modelSelection.model_name}
                          onChange={(e) => setModelSelection({ ...modelSelection, model_name: e.target.value })}
                          label="Name Your Model"
                          variant="outlined"
                          className="w-10/12"
                          helperText="💡 Choose a unique name for your deployed model. This will help identify it in the system."
                        />
                        <TextField
                          id="model-description"
                          label="Description"
                          value={modelSelection.model_description}
                          onChange={(e) => setModelSelection({ ...modelSelection, model_description: e.target.value })}
                          multiline
                          rows={4}
                          className="w-full"
                          helperText="💡 Provide a brief description of the model to better understand its purpose and functionality."
                        />
                      </div>
                      <div className="mt-5 mb-3 sm:mt-6 flex justify-end">
                        <button
                          type="button"
                          className="border border-black rounded-lg h-[48px] w-[115px] hover:bg-gray-200 hover:text-gray-600 hover:border-gray-300 hover:shadow-md transition duration-200 ease-in-out focus:outline-none text-[16px] font-[600] justify-center items-center mr-4"
                          onClick={() => setIsOpen(false)}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="classic-button h-[48px] w-[110px] text-[18px] font-[600]"
                          onClick={handleNext}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}

                  {activeStep === 1 && (
                    <div className="w-9/12 h-full flex flex-col p-6">
                      <p className="text-justify text-[18px] font-[600]">Model Selection</p>
                      <div className="my-10 text-left sm:mt-5 space-y-6">
                        <TextField
                          label="Hugging Face Model"
                          name="huggingfacemodel"
                          value={modelSelection.huggingfacemodel}
                          onChange={(e) => setModelSelection({ ...modelSelection, huggingfacemodel: e.target.value })}
                          className="w-10/12"
                          variant="outlined"
                          helperText="💡 For the Hugging Face Model ID, please provide the exact Hugging Face Model ID as listed on Hugging Face. It's crucial that the Hugging Face Model ID matches exactly, as this ensures the correct model is downloaded and available for use. Double-check the Hugging Face Model ID to avoid errors, as using an incorrect or incomplete Hugging Face Model ID will prevent successful downloads or deployments."
                        />
                        <TextField
                          label="Hugging Face Token"
                          name="huggingFaceToken"
                          value={modelSelection.huggingFaceToken}
                          onChange={(e) => setModelSelection({ ...modelSelection, huggingFaceToken: e.target.value })}
                          className="w-10/12"
                          variant="outlined"
                          type={showToken ? "text" : "password"} 
                          autoComplete="new-password" // Prevent autofill
                          helperText="💡 Please ensure that you use a Hugging Face token with full access to download models. The token must have the appropriate permissions to authenticate and interact with Hugging Face's model repository. If your token does not have sufficient access, you may face issues when trying to download models or use them for inference. Make sure your token is linked to an account that has the necessary access rights to the models you intend to use."
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowToken(!showToken)}
                                  edge="end"
                                >
                                  {showToken ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          
                          }}
                        />
                      </div>
                      <div className="mt-5 mb-3 sm:mt-6 flex justify-end">
                        <button
                          type="button"
                          className="border border-black rounded-lg h-[48px] w-[115px] hover:bg-gray-200 hover:text-gray-600 hover:border-gray-300 hover:shadow-md transition duration-200 ease-in-out focus:outline-none text-[16px] font-[600] justify-center items-center mr-4"
                          onClick={handleBack}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="classic-button h-[48px] w-[110px] text-[18px] font-[600]"
                          onClick={validateModelAndToken}
                          disabled={loading}
                        >
                          {loading ? "Validating..." : "Next"}
                        </button>
                      </div>
                    </div>
                  )}

                  {activeStep === 2 && (
                    <div className="w-10/12 h-full flex flex-col px-8 py-6">
                      <p className="text-justify text-[18px] font-semibold mb-6">GPU Selection</p>
                      <div className="flex flex-col space-y-6">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                          {gpuOptions.map((gpu, index) => (
                            <div
                              key={index}
                              className={`px-2 border rounded-lg cursor-pointer shadow-sm min-w-fit ${
                                selectedGPU === gpu.name ? "border-purple-500 bg-purple-50" : "border-gray-200"
                              } hover:border-purple-400`}
                              onClick={() => handleSelectGPU(gpu.name, gpu.details)}
                            >
                              <div className="flex items-center space-x-2">
                                <input
                                  type="radio"
                                  checked={selectedGPU === gpu.name}
                                  onChange={() => handleSelectGPU(gpu.name, gpu.details)}
                                  className="form-radio text-purple-500 focus:ring-purple-500"
                                />
                                <img src={nvidiaLogo} alt="Nvidia Logo" className="" />
                                <p className="mt-2 pt-2 font-semibold text-sm">{gpu.name}</p>
                              </div>
                              <p className="text-sm text-gray-500 my-2 ml-6">
                                from ${gpu.details[0]?.price || 0} / hr
                              </p>
                            </div>
                          ))}
                        </div>
                        {/* GPU Count Slider with Helper Text */}
                        {selectedGPU && (
                          <div className="flex items-center mb-6">
                            <label className="flex items-center text-sm mr-3 font-semibold">
                              GPU Count
                              <FaInfoCircle className="ml-1 text-gray-400" />
                            </label>
                            <Slider
                              value={gpuCount}
                              onChange={handleSliderChange}
                              min={1}
                              max={Math.max(...gpuCountOptions.map((option) => option.gpu_count))}
                              step={1}
                              valueLabelDisplay="auto"
                              marks={gpuCountOptions.map((option) => ({
                                value: option.gpu_count,
                                label: `${option.gpu_count}`,
                              }))}
                              style={{ accentColor: "#8F27C1" }}
                            />
                            {/* <span className="font-semibold">{gpuCount}</span> */}
                            <p className="bg-gray-100 text-right font-semibold text-green-600 ml-6 border rounded-md p-2">
                              ${getTotalPrice()} / hr
                            </p>
                          </div>
                        
                          
                          
                        )}
                        <div>
                          <p className="text-sm text-gray-500 mt-2">
                            💡 <strong>Tip:</strong> If you want your models to run faster or handle larger models, consider using a higher GPU count. High-end GPUs provide better performance for resource-intensive tasks.
                          </p>
                        </div>

                        {/* Auto Stop Input */}
                        <div className="flex flex-col">
                          <TextField
                            label="Auto Stop (in hours)"
                            name="auto_stop"
                            value={autoStop}
                            onChange={handleAutoStopChange}
                            type="number"
                            inputProps={{ min: 1 }}
                            className="w-10/12"
                            variant="outlined"
                            helperText="💡 Set the number of hours after which the model will automatically stop. This helps manage costs and resources efficiently."
                          />
                          </div>
                      </div>
                      <div className="mt-8 flex justify-end">
                        <button
                          type="button"
                          className="border border-black rounded-lg h-[48px] w-[115px] hover:bg-gray-200 hover:text-gray-600 hover:border-gray-300 hover:shadow-md transition duration-200 ease-in-out focus:outline-none text-[16px] font-semibold mr-4"
                          onClick={handleBack}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="classic-button h-[48px] w-[110px] text-[18px] font-semibold"
                          onClick={handleTune}
                          disabled={loading || !selectedGPU}
                        >
                          {loading ? "Deploying..." : "Deploy"}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <AddCreditModal isOpen={isCreditOpen} setIsOpen={setIsCreditOpen} />
    </>
  );
};

export default AddHuggingModal;