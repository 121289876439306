import React, { useContext, useState } from "react";
import { ImSpinner3 } from "react-icons/im";
import { toast } from "react-toastify";
import {
  getInstanceStatus,
  getInstanceStatusStopper,
  getSpeechToText,
  getStatusLight,
} from "../../../services/api";
import { Context } from "../../../Context";
import axiosInstance from "../../../api/axios";
import AddCreditModal from "../../AddCreditModal";

const SpeechToText = ({ endpoint, isActive, ins_id, modelName,credit }) => {
  const { setModelStatusLight } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [initialising, setInitialising] = useState(false);

  const [userQuery, setUserQuery] = useState(null);
  const [translation, setTranslation] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const checkInstance = async () => {
    setTranslation("");
    if (!userQuery) {
      toast.warning("Upload File!");
      return;
    }
    setInitialising(true);
    setModelStatusLight("initialising");

    try {
      // Step 1: Check for available credits (GET request)
      const rescheck = await axiosInstance.get(
        `payments/availableCredit/?model-name=${modelName}`
      );

      if (rescheck.data.data.status) {
        // Step 2: Credit is available, proceed to deduct it (POST request)
        const deductCredit = await axiosInstance.post(
          "payments/availableCredit/",
          {
            model_name: modelName,
            add: false,
            sub: true,
            amount: 0,
          }
        );

        if (deductCredit.data.data.status) {
          // Proceed with the instance status check since credit deduction was successful
          const res = await getInstanceStatus({ instance_id: ins_id });
          console.log(res);

          if (res.status === 200) {
            setLoading(true);
            setInitialising(false);

            try {
              const responseLight = await getStatusLight({
                instance_id: ins_id,
              });
              setModelStatusLight(responseLight.data.status);
            } catch (error) {
              console.log(error.message);
              await refundCredit(); // Refund credit if something goes wrong
            }

            // Trigger handleClick for text generation
            handleClick(deductCredit);
          }
        } else {
          // Failed to deduct credit, stop further execution
          toast.error(deductCredit.data.message);
          setLoading(false);
          setInitialising(false);
          return false; // Stop further execution
        }
      } else {
        // If no credit, show warning and stop the process
        toast.error(rescheck.data.message);
        setIsOpen(true); // Open AddCreditModal when no credit
        setLoading(false);
        setInitialising(false);
        return false; // Stop further execution
      }
    } catch (error) {
      toast.error("Something Went Wrong,Please Try Again!");
      setLoading(false);
      setInitialising(false);
    }
  };

  // Function to refund credit
  const refundCredit = async () => {
    try {
      await axiosInstance.post("payments/availableCredit/", {
        model_name: modelName,
        add: true,
        sub: false,
        amount: 0,
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  const audioToText = (e) => {
    setUserQuery(e.target.files[0]);

    const fileInput = e.target;
    const file = fileInput.files[0];
    if (file) {
      const acceptedTypes = ["audio/mp3", "audio/mpeg"]; // Accepted file types
      if (!acceptedTypes.includes(file.type)) {
        // Check if the file type is in the accepted types
        toast.warning("Please upload mp3 file only");
        fileInput.value = "";
        return;
      }

      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 2) {
        toast.warning("File size exceeds 2MB limit.");
        fileInput.value = "";
        return;
      }
    }
  };

  const handleClick = async (deductCredit) => {
    setTranslation("");
    if (!userQuery) {
      toast.warning("Upload File!");
      return;
    }

    const formData = new FormData();
    formData.append("file", userQuery);

    try {
      const response = await getSpeechToText(endpoint, formData);
      setTranslation(response.data);
      toast.success(deductCredit.data.message);
      if (
        deductCredit.data.message ===
        "You have used 1 out of 1 free inferencing credits."
      ) {
        infInstanceStatus();
      }
    } catch (error) {
      console.error("Error translating audio:", error);
      toast.error("Error translating audio");
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      }
      await refundCredit(); // Refund credit on error
    } finally {
      setLoading(false);
      const res = await getInstanceStatusStopper({ instance_id: ins_id });
      console.log(res);
    }
  };

  const infInstanceStatus = async () => {
    try {
      await axiosInstance.post("payments/updateStatus/", {
        all_free_credits_used: true,
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  return (
    <div className="flex w-full h-full">
      {/* User Interaction Section */}
      <div className="w-1/2 h-full p-8">
        <p className="text-[16px] font-[600] mb-8">Generate Text from Audio</p>
        <p className="text-[15px] font-[500] -mt-6 mb-6">
          Running this model will deduct{" "}
          <span className="text-[#008B16] font-[800]">${credit}</span> / request
          from your balance.{" "}
        </p>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 ">
            Audio Query:
          </label>
          <input
            type="file"
            accept="audio/mp3"
            className="w-full py-2 focus:outline-none border rounded px-2 focus:border-blue-500 "
            onChange={(e) => audioToText(e)}
          />
        </div>

        {loading ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Running
          </button>
        ) : initialising ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Initializing
          </button>
        ) : (
          <button
            disabled={!isActive}
            className={`${
              isActive ? "classic-button" : "disabled-button"
            } w-[136px] h-[52px] mx-auto `}
            onClick={() => checkInstance()}
          >
            Run
          </button>
        )}
        {initialising && (
          <p className="text-gen-color mt-4 text-[14px] font-[600]">
            The Model instance is initializing, This will take 1 to 2 mins. Once
            the instance is active we will process your request
          </p>
        )}
      </div>
      {/* Response Section */}
      <div className="w-1/2 min-h-[538px] p-8 border-l-2 ">
        <p className="text-[16px] font-[600] mb-8">Response</p>

        {translation && (
          <>
            <p className="text-md mb-2">
              Language Detected: {translation.audio_language.toUpperCase()}
            </p>
            <p className="h-[140px] text-base text-gray-700 border border-gray-300 resize-y w-full p-3 bg-white rounded-lg overflow-y-scroll scrollbar-width-half">
              {translation.result_transcribe}
            </p>
            <p className="h-[140px] text-base text-gray-700 mt-4 border border-gray-300  w-full p-3 bg-white rounded-lg  overflow-y-scroll scrollbar-width-half">
              {translation.result_translate}
            </p>
          </>
        )}
      </div>
      {/* Add Credit Modal */}
      <AddCreditModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default SpeechToText;
