import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import {
  IconButton,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
} from "@mui/material";
import { MdClose, MdContentCopy, MdRefresh } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";

const GetApiKeyModal = ({ isGetAPIModalOpen, setIsGetAPIModalOpen  }) => {
  const [apiKey, setApiKey] = useState("");
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch API Key
  const fetchApiKey = async (regenerate = false) => {
    try {
      setLoading(true);
      setError(null);
      const response = await axiosInstance.post("/nocode/generate_api_key", {
        regenerate_key: regenerate, // Pass `true` for regeneration
      });
      setApiKey(response.data.data.api_key);
      // sessionStorage.setItem("apiKey", response.data.data.api_key);
    } catch (err) {
      setError("Failed to fetch API key. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isGetAPIModalOpen) fetchApiKey(false); // Fetch the API key when the modal opens
  }, [isGetAPIModalOpen]);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleClose = () => {
    setIsGetAPIModalOpen(false);
  };

  return (
    isGetAPIModalOpen && (
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        {/* Dark Overlay */}
        <div className="fixed inset-0 bg-black opacity-50"></div>

        {/* Modal Content */}
        <div className="relative z-50 w-full max-w-lg mx-auto bg-white rounded-lg shadow-lg p-6">
          {/* Header */}
          <div className="flex items-center justify-between pb-4 border-b">
            <Typography variant="h6" className="text-gray-800 font-semibold">
              Get API Key
            </Typography>
            <IconButton onClick={handleClose}>
              <MdClose className="w-6 h-6 text-gray-500 hover:text-black" />
            </IconButton>
          </div>

          {/* Body */}
          <div className="mt-4 space-y-4">
            <Typography className="text-sm text-gray-600">
              Use this key in your model by passing it with the <b>API_KEY</b> parameter.
            </Typography>

            <Typography className="text-sm text-red-600 font-semibold">
              <b>Important:</b> Please copy and securely store your API key. It will only be displayed once upon generation.  
              If you lose this key, you will need to regenerate a new one.
            </Typography>

            {/* API Key Display */}
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="API Key"
              value={apiKey || "Fetching..."}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard text={apiKey} onCopy={handleCopy}>
                      <Tooltip title={copied ? "Copied!" : "Copy to Clipboard"} arrow>
                        <IconButton>
                          <MdContentCopy className="text-xl text-gray-600 hover:text-black" />
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />

            {/* Error Message */}
            {error && <Typography color="error">{error}</Typography>}

            {/* Buttons */}
            <div className="flex justify-end gap-2">
              <Button onClick={handleClose} variant="outlined" color="secondary">
                Close
              </Button>
              <Button
                onClick={() => fetchApiKey(true)}
                variant="contained"
                color="primary"
                startIcon={loading ? <CircularProgress size={16} /> : <MdRefresh />}
                disabled={loading}
              >
                Regenerate API Key
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default GetApiKeyModal;
