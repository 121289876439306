import { TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { ImSpinner3 } from "react-icons/im";
import {
  getInstanceStatus,
  getCodeGeneration,
  getStatusLight,
  getInstanceStatusStopper,
} from "../../../services/api";
import { toast } from "react-toastify";
import { Context } from "../../../Context";
import CodeBlock from "../../CodeBlock";
import Markdown from "react-markdown";
import axiosInstance from "../../../api/axios";
import AddCreditModal from "../../AddCreditModal";

const TextToCode = ({
  endpoint,
  isActive,
  ins_id,
  model_full_name,
  modelName,
  credit
}) => {
  const { setModelStatusLight } = useContext(Context);
  const [userQuery, setUserQuery] = useState("");
  const [llmNewTokens, setLlmNewTokens] = useState(1000);
  const [loading, setLoading] = useState(false);
  const [temp, setTemp] = useState(0.5);
  const [initialising, setInitialising] = useState(false);
  const [response, setResponse] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const position = ((llmNewTokens - 50) / (10000 - 50)) * 100; // Calculate position based on slider value

  const checkInstance = async () => {
    setResponse("");
    if (!userQuery) {
      toast.warning("User Query Required");
      setLoading(false);
      return;
    }
    setInitialising(true);
    setModelStatusLight("initialising");

    try {
      // Step 1: Check for available credits (GET request)
      const rescheck = await axiosInstance.get(
        `payments/availableCredit/?model-name=${modelName}`
      );

      if (rescheck.data.data.status) {
        // Step 2: Credit is available, proceed to deduct it (POST request)
        const deductCredit = await axiosInstance.post(
          "payments/availableCredit/",
          {
            model_name: modelName,
            add: false,
            sub: true,
            amount: 0,
          }
        );

        if (deductCredit.data.data.status) {
          // Proceed with the instance status check since credit deduction was successful
          const res = await getInstanceStatus({ instance_id: ins_id });
          console.log(res);

          if (res.status === 200) {
            setLoading(true);
            setInitialising(false);

            try {
              const responseLight = await getStatusLight({
                instance_id: ins_id,
              });
              setModelStatusLight(responseLight.data.status);
            } catch (error) {
              console.log(error.message);
              await refundCredit(); // Refund credit if something goes wrong
            }

            // Trigger handleClick for text generation
            handleClick(deductCredit);
          }
        } else {
          // Failed to deduct credit, stop further execution
          toast.error(deductCredit.data.message);
          setLoading(false);
          setInitialising(false);
          return false; // Stop further execution
        }
      } else {
        // If no credit, show warning and stop the process
        toast.error(rescheck.data.message);
        setIsOpen(true); // Open AddCreditModal when no credit
        setLoading(false);
        setInitialising(false);
        return false; // Stop further execution
      }
    } catch (error) {
      toast.error("Something Went Wrong,Please Try Again!");
      setLoading(false);
      setInitialising(false);
    }
  };

  // Function to refund credit
  const refundCredit = async () => {
    try {
      await axiosInstance.post("payments/availableCredit/", {
        model_name: modelName,
        add: true,
        sub: false,
        amount: 0,
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  const renderResponse = (text) => {
    const parts = text.split(/(```[\s\S]*?```)/);
    return parts.map((part, index) => {
      if (part.startsWith("```") && part.endsWith("```")) {
        const [, language, ...codeLines] = part.split("\n");
        const code = codeLines.slice(0, -1).join("\n");
        return <CodeBlock key={index} code={code} language={language.trim()} />;
      } else {
        return (
          <Markdown
            key={index}
            className="text-base text-gray-700 text-justify my-4"
          >
            {part}
          </Markdown>
        );
      }
    });
  };

  const handleClick = async (deductCredit) => {
    setResponse("");
    if (!userQuery) {
      toast.warning("User Query Required");
      setLoading(false);
      return;
    }

    try {
      const payload = {
        user_input: userQuery,
        max_new_tokens: parseInt(llmNewTokens),
        temperature: parseFloat(temp),
      };

      if (
        model_full_name === "CodeLlama-7b-Instruct-hf" ||
        model_full_name === "CodeQwen-1.5-7b-it"
      ) {
        payload.top_p = 1;
        payload.repetition_penalty = 1;
      }

      const apiResponse = await getCodeGeneration(endpoint, payload);
      setResponse(apiResponse.data.response);
      toast.success(deductCredit.data.message);
      if (
        deductCredit.data.message ===
        "You have used 1 out of 1 free inferencing credits."
      ) {
        infInstanceStatus();
      }
    } catch (error) {
      console.log(error);
      await refundCredit(); // Refund credit on error
    } finally {
      setLoading(false);
      const res = await getInstanceStatusStopper({ instance_id: ins_id });
      console.log(res);
    }
  };

  const infInstanceStatus = async () => {
    try {
      await axiosInstance.post("payments/updateStatus/", {
        all_free_credits_used: true,
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  return (
    <div className="flex w-full h-full">
      {/* User Interaction Section */}
      <div className="w-1/2 h-full p-8">
        <p className="text-[16px] font-[600] mb-8">Generate Response</p>
        <p className="text-[15px] font-[500] -mt-6 mb-6">
          Running this model will deduct{" "}
          <span className="text-[#008B16] font-[800]">${credit}</span> / request
          from your balance.{" "}
        </p>
        <TextField
          className="w-full "
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey) {
              e.preventDefault();
              setUserQuery((prevValue) => prevValue + "\n");
            } else if (e.key === "Enter") {
              e.preventDefault();
              setUserQuery(e.target.value);
              checkInstance();
            }
          }}
          id="outlined-multiline-static"
          label="User Query"
          multiline
          rows={4}
          value={userQuery}
          onChange={(e) => setUserQuery(e.target.value)}
        />

        {/* Max New Tokens Slider Component */}
        <div className=" mb-5 mt-6 max-w-full overflow-hidden">
          <label className="block text-gray-700 text-sm font-medium">
            Max New Tokens:
          </label>
          <div className="relative mt-8 max-w-full">
            <span
              className={`podition-slider absolute top-9 right-1 bg-white h-8 w-16 flex items-center justify-center transform -translate-x-1/2 -translate-y-full border border-black rounded-lg px-5 text-sm font-medium`}
            >
              {llmNewTokens}
            </span>
            <input
              type="range"
              min="50"
              max="10000"
              step="50"
              className="w-full py-2 max-w-full lg:max-w-xs"
              value={llmNewTokens}
              onChange={(e) => setLlmNewTokens(parseInt(e.target.value))}
              style={{
                "--value": llmNewTokens,
                "--min": 50,
                "--max": 10000,
              }}
            />
            <div className="flex justify-between w-[16.82vw] lg:max-w-xs -mt-2">
              <span>50</span>
              <span>10000</span>
            </div>
          </div>
        </div>

        {/* Temperature Slider Component */}
        <div className=" mb-5 mt-6 max-w-full overflow-hidden">
          <label className="block text-gray-700 text-sm font-medium">
            Temperature:
          </label>
          <div className="relative mt-8 max-w-full">
            <span
              className={`podition-slider absolute top-9 right-1 bg-white h-8 w-16 flex items-center justify-center transform -translate-x-1/2 -translate-y-full border border-black rounded-lg px-5 text-sm font-medium`}
            >
              {temp}
            </span>
            <input
              type="range"
              min="0"
              max="2"
              step="0.01"
              className="w-full py-2 max-w-full lg:max-w-xs"
              value={temp}
              onChange={(e) => setTemp(parseFloat(e.target.value))}
              style={{
                "--value": temp,
                "--min": 0,
                "--max": 2,
              }}
            />
            <div className="flex justify-between w-[16.82vw] lg:max-w-xs -mt-2">
              <span>0</span>
              <span>2</span>
            </div>
          </div>
        </div>

        {loading ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Running
          </button>
        ) : initialising ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Initializing
          </button>
        ) : (
          <button
            disabled={!isActive}
            className={`${
              isActive ? "classic-button" : "disabled-button"
            } w-[136px] h-[52px] mx-auto`}
            onClick={() => checkInstance()}
          >
            Run
          </button>
        )}
        {initialising && (
          <p className="text-gen-color mt-4 text-[14px] font-[600]">
            The Model instance is initializing, This will take 1 to 2 mins. Once
            the instance is active we will process your request
          </p>
        )}
      </div>
      {/* Response Section */}
      <div className="w-1/2 p-8 border-l-2 overflow-y-hidden">
        <p className="text-[16px] font-[600] mb-8">Response</p>
        <div
          className={`${
            response.length > 0 ? "border" : "hidden"
          } border-gray-300 rounded-lg py-2 h-5/6 overflow-y-scroll`}
        >
          {response && (
            <div className="bg-white p-3 w-full resize-y">
              {renderResponse(response)}
            </div>
          )}
        </div>
      </div>
      {/* Add Credit Modal */}
      <AddCreditModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default TextToCode;
