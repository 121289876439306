import React, { useContext, useRef, useState } from "react";
import { ImSpinner3 } from "react-icons/im";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { IoCloseCircleSharp } from "react-icons/io5";
import {
  getInstanceStatus,
  getInstanceStatusStopper,
  getObjectDetection,
  getStatusLight,
} from "../../../services/api";
import { Context } from "../../../Context";
import axiosInstance from "../../../api/axios";
import AddCreditModal from "../../AddCreditModal";

const ObjectDetection = ({ endpoint, isActive, ins_id, modelName,credit }) => {
  const { setModelStatusLight } = useContext(Context);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialising, setInitialising] = useState(false);
  const fileInputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const checkInstance = async () => {
    setProcessedImage("");
    if (!uploadedImage) {
      toast.warning("Upload Image!");
      return;
    }
    setInitialising(true);
    setModelStatusLight("initialising");

    try {
      // Step 1: Check for available credits (GET request)
      const rescheck = await axiosInstance.get(
        `payments/availableCredit/?model-name=${modelName}`
      );

      if (rescheck.data.data.status) {
        // Step 2: Credit is available, proceed to deduct it (POST request)
        const deductCredit = await axiosInstance.post(
          "payments/availableCredit/",
          {
            model_name: modelName,
            add: false,
            sub: true,
            amount: 0,
          }
        );

        if (deductCredit.data.data.status) {
          // Proceed with the instance status check since credit deduction was successful
          const res = await getInstanceStatus({ instance_id: ins_id });
          console.log(res);

          if (res.status === 200) {
            setLoading(true);
            setInitialising(false);

            try {
              const responseLight = await getStatusLight({
                instance_id: ins_id,
              });
              setModelStatusLight(responseLight.data.status);
            } catch (error) {
              console.log(error.message);
              await refundCredit(); // Refund credit if something goes wrong
            }

            // Trigger handleClick for text generation
            handleClick(deductCredit);
          }
        } else {
          // Failed to deduct credit, stop further execution
          toast.error(deductCredit.data.message);
          setLoading(false);
          setInitialising(false);
          return false; // Stop further execution
        }
      } else {
        // If no credit, show warning and stop the process
        toast.error(rescheck.data.message);
        setIsOpen(true); // Open AddCreditModal when no credit
        setLoading(false);
        setInitialising(false);
        return false; // Stop further execution
      }
    } catch (error) {
      toast.error("Something Went Wrong,Please Try Again!");
      setLoading(false);
      setInitialising(false);
    }
  };

  // Function to refund credit
  const refundCredit = async () => {
    try {
      await axiosInstance.post("payments/availableCredit/", {
        model_name: modelName,
        add: true,
        sub: false,
        amount: 0,
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file && file.type.startsWith("image/")) {
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setUploadedImage(file);
          setProcessedImage(null);
        };
        reader.readAsDataURL(file);
      } else {
        toast.error("File size exceeds 5MB limit.");
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file.size <= maxSize) {
      setUploadedImage(file);
      setProcessedImage(null);
    } else {
      toast.error("File size exceeds 5MB limit.");
    }
  };

  const handleBoxClick = () => {
    fileInputRef.current.click();
  };
  const openModal = (img) => {
    if (img === uploadedImage) {
      setSelectedImage(URL.createObjectURL(img));
      setModalIsOpen(true);
    } else {
      setSelectedImage(img);
      setModalIsOpen(true);
    }
  };
  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  const handleClick = async (deductCredit) => {
    setProcessedImage(null);
    if (!uploadedImage) {
      toast.warning("Upload Image!");
      return;
    }

    setProcessedImage(null);

    const formData = new FormData();
    formData.append("image", uploadedImage);

    try {
      const response = await getObjectDetection(endpoint, formData);

      const processedImageData = response.data;
      const processedImageUrl = URL.createObjectURL(processedImageData);
      setProcessedImage(processedImageUrl);
      toast.success(deductCredit.data.message);
      if (
        deductCredit.data.message ===
        "You have used 1 out of 1 free inferencing credits."
      ) {
        infInstanceStatus();
      }
    } catch (error) {
      toast("Error processing image");
      console.error("Error processing image:", error);
      await refundCredit(); // Refund credit on error
    } finally {
      setLoading(false);
      const res = await getInstanceStatusStopper({ instance_id: ins_id });
      console.log(res);
    }
  };

  const infInstanceStatus = async () => {
    try {
      await axiosInstance.post("payments/updateStatus/", {
        all_free_credits_used: true,
      });
      console.log("Credit refunded successfully");
    } catch (error) {
      console.log("Failed to refund credit:", error.message);
    }
  };

  return (
    <div className="flex w-full h-full">
      {/* User Interaction Section */}
      <div className="w-1/2 h-full p-8">
        <p className="  -[16px] font-[600] mb-8">Generate Response</p>
        {/* Image Drag and Drop Section */}
        <p className="text-[15px] font-[500] -mt-6 mb-6">
          Running this model will deduct{" "}
          <span className="text-[#008B16] font-[800]">${credit}</span> / request
          from your balance.{" "}
        </p>
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={handleBoxClick}
          className="mb-10 flex justify-center items-center h-[150px] w-full bg-white border-dashed border-2 border-gray-400 rounded-md cursor-pointer"
        >
          <div className="flex flex-col items-center hover:text-blue-500">
            <span>Click to upload or Drag & Drop</span>
          </div>
          <input
            type="file"
            id="fileInput"
            accept="image/png, image/jpeg, image/jpg"
            className="hidden"
            onChange={handleFileInputChange}
            ref={fileInputRef}
          />
        </div>

        {loading ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Running
          </button>
        ) : initialising ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Initializing
          </button>
        ) : (
          <button
            disabled={!isActive}
            className={`${
              isActive ? "classic-button" : "disabled-button"
            } w-[136px] h-[52px] mx-auto `}
            onClick={() => checkInstance()}
          >
            Run
          </button>
        )}
        {initialising && (
          <p className="text-gen-color mt-4 text-[14px] font-[600]">
            The Model instance is initializing, This will take 1 to 2 mins. Once
            the instance is active we will process your request
          </p>
        )}
      </div>

      {/* Response Section */}
      <div className="w-1/2 min-h-[538px] p-8 border-l-2 ">
        <p className="text-[16px] font-[600] mb-8">Response</p>

        <div className="grid grid-cols-2 gap-2 mt-4 h-[320px] overflow-scroll scrollbar-width-half rounded-sm">
          <div className="">
            {uploadedImage && (
              <img
                src={URL.createObjectURL(uploadedImage)}
                alt="Uploaded"
                className="max-h-[300px] mx-auto rounded-md cursor-pointer"
                onClick={() => openModal(uploadedImage)}
              />
            )}
          </div>
          <div className="">
            {processedImage && (
              <img
                src={processedImage}
                alt="Processed"
                className="max-h-[300px] mx-auto rounded-md cursor-pointer"
                onClick={() => openModal(processedImage)}
              />
            )}
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Enlarged Image"
          style={{
            overlay: {
              zIndex: "9999",
            },
            content: {
              width: "fit-content",
              maxHeight: "auto",
            },
          }}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
          <button
            className="absolute -top-3 -right-3 m-4 text-[#e81123] text-3xl"
            onClick={closeModal}
          >
            <IoCloseCircleSharp />
          </button>
          {selectedImage && (
            <img src={selectedImage} alt="Enlarged" className="rounded-lg" />
          )}
        </Modal>
      </div>
      {/* Add Credit Modal */}
      <AddCreditModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default ObjectDetection;
