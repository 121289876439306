import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoCodeSharp } from "react-icons/io5";
import GetApiKeyModal from "../UserProfileActions/GetApiKeyModal";
import AddCreditModal from "../AddCreditModal";
import Slider from "../Slider";
import axios from "axios";
import axiosInstance from "../../api/axios";
import { useLocation } from "react-router-dom";
import { ImSpinner3 } from "react-icons/im";
import Markdown from "react-markdown";
import { BsDot } from "react-icons/bs";
import { toast } from "react-toastify";
// import axiosInstance from "../../api/axios";

const GetUiT = ({ modelData, category, modelStatusLight }) => {
  const location = useLocation();
  const { model, modelIp } = location.state || {};
  // const model = "Gemma"
  // const modelIp = "aaaaaaaaaaaaaaaaa"

  const [isGetAPIModalOpen, setIsGetAPIModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [maxTokens, setMaxTokens] = useState(150);
  const [temperature, setTemperature] = useState(0.7);
  const [topP, setTopP] = useState(0.9);
  const [repetitionPenalty, setRepetitionPenalty] = useState(1.2);
  const [response, setResponse] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [showApiKey, setShowApiKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialising, setInitialising] = useState(false);
  const [isActive, setIsActive] = useState(true);

  // useEffect(() => {
  //   const fetchApiKey = async () => {
  //     const storedApiKey = sessionStorage.getItem("apiKey");
  //     if (storedApiKey) {
  //       setApiKey(storedApiKey);
  //     } else {
  //       try {
  //         const response = await axiosInstance.post("/nocode/generate_api_key");
  //         const apiKey = response.data.data.api_key;
  //         sessionStorage.setItem("apiKey", apiKey);
  //         setApiKey(apiKey);
  //       } catch (error) {
  //         console.error("Error generating API key:", error);
  //       }
  //     }
  //   };

  //   fetchApiKey();
  // }, []);

  const handleGetAPIToken = () => {
    setIsGetAPIModalOpen(true);
  };

  // const saveApiKey = (key) => {
  //   setApiKey(key);
  //   sessionStorage.setItem("apiKey", key);
  // };

  const checkInstance = async () => {
    setLoading(true);
    setLoading(false);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // if (!apiKey) {
    //         toast.warn("Please enter your API key before proceeding.");
    //         return;
    //     }
    if (!query) {
            toast.warn("Please enter a query before submitting.");
            return;
        }

    setInitialising(true);
    try {
      // const headers = {
      //   Authorization: apiKey,
      //   "Content-Type": "application/json",
      // };

      const data = {
        prompt: query,
        max_new_tokens: Number(maxTokens),
        temperature: Number(temperature),
        top_p: Number(topP),
        repetition_penalty: Number(repetitionPenalty),
      };

      // const response = await axios.post(modelIp, data, { headers });
      const response = await axiosInstance.post(modelIp, data);
      setResponse(response.data.data);
    } catch (error) {
      console.error("API call failed:", error);
      setResponse({ error: "Failed to fetch response. Please try again." });
    } finally {
      setInitialising(false);
    }
  };

  return (
    <div className="flex w-full min-h-screen">
      <Sidebar className="flex-shrink-0 h-full fixed" />
      <div className="flex flex-col w-full">
        <div className="ml-[90px]">
          <Navbar className="flex-shrink-0 fixed w-full" />
        </div>

        <div className="ml-[90px] px-2 pt-[84px] mb-24">
          <Container sx={{ maxWidth: { xs: "100%", sm: "1252px" } }}>
            {/* Breadcrumbs */}
            <div className="flex items-center py-4">
              <Link to="/myInstance" state={{ openTab: 2 }}>
                <Typography
                  variant="h6"
                  component="h2"
                  className="text-xl font-bold text-heading-black"
                >
                  Dashboard
                </Typography>
              </Link>
              <MdKeyboardArrowRight className="text-2xl" />
              <Typography
                variant="h6"
                component="h2"
                className="text-xl font-bold text-heading-black"
              >
                Interactive UI
              </Typography>
            </div>

            {/* Model Info Section */}
            <div className="min-h-[132px] w-full bg-white pl-4 pr-8 mb-4 shadow-md flex flex-wrap justify-between items-center">
              <div className="flex flex-wrap gap-x-12">
                <img
                  src="https://ai-platform-images.s3.amazonaws.com/AI-Image/Subcategories/Subcategories/Gemma.png"
                  className="h-[100px] w-[140px] border rounded-md"
                  alt="Model Image"
                />
                <div>
                  <span className="text-[21px] font-[600]">
                    {model.model_name}
                  </span>
                  <div className="flex flex-wrap gap-x-12 mt-4">
                    <div>
                      <p className="text-[14px] font-[400]">Category</p>
                      <p className="text-[14px] font-[500]">Text-Generation</p>
                    </div>
                    <div>
                      <p className="text-[14px] font-[400]">Visibility</p>
                      <p className="text-[14px] font-[500]">Private</p>
                    </div>
                    <div>
                      <p className="text-[14px] font-[400]">Status</p>

                      <p className="text-[14px] font-[500] flex items-center -mt-1 text-[#008b16]">
                        <BsDot className="text-3xl -mx-2" /> On
                      </p>
                    </div>
                    {/* <div>
                      <p className="text-[14px] font-[400]">Links</p>
                      
                        <a href="https://ai.google.dev/gemma/terms" target="blank">
                          <span className="text-[14px] font-[500] hover:text-blue-500">
                            <u>Paper</u>
                          </span>
                        </a>
                      
                      <span className="text-[14px] font-[500]"> and </span>
                      
                        <a href="https://ai.google.dev/gemma/terms" target="blank">
                          <span className="text-[14px] font-[500] hover:text-blue-500">
                            <u>License</u>
                          </span>
                        </a>
                      
                    </div> */}
                  </div>
                </div>
              </div>
              {/* Get API Token Button */}
              {/* <button
                className="classic-button flex items-center justify-center h-[40px] min-w-[150px] text-[14px] font-semibold mt-2"
                onClick={handleGetAPIToken}
              >
                <IoCodeSharp className="mr-1" />
                <span>Get API Token</span>
              </button> */}
            </div>

            

            {/* Form Section */}
            <Paper elevation={3} sx={{ p: 2, mb: 4, bgcolor: "#fff" }}>
              <Box className="flex w-full h-full">
                <div className="w-1/2 h-full p-8">
                  <p className="text-[16px] font-[600] mb-8">
                    Generate Response
                  </p>
                  <div>
                  {/* <TextField
              type={showApiKey ? "text" : "password"}
              label="API Key"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              fullWidth
              placeholder="Enter your API key"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowApiKey(!showApiKey)}>
                      {showApiKey ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
                  </div>

                  <br></br>
                  {/* <TextField
                    label="API Key"
                    placeholder="API Key"
                    fullWidth
                    value={apiKey}
                    onChange={(e) => saveApiKey(e.target.value)}
                    sx={{ marginBottom: 2 }}
                  /> */}
                  <TextField
                    className="w-full"
                    label="User Query"
                    multiline
                    rows={4}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.shiftKey) {
                        e.preventDefault();
                        setQuery((prev) => prev + "\n");
                      } else if (e.key === "Enter") {
                        e.preventDefault();
                        handleFormSubmit();
                      }
                    }}
                  />

                  <div className="mb-10 mt-6">
                    <Slider
                      label="Max New Tokens"
                      value={maxTokens}
                      setValue={(newValue) => setMaxTokens(Number(newValue))}
                      min={100}
                      max={2000}
                      step={50}
                      text={
                        "The maximum numbers of tokens to generate, ignoring the number of tokens in the prompt.(One token is roughly 4 characters for standard English text)"
                      }
                    />

                    <Slider
                      label="Temperature"
                      value={temperature}
                      setValue={(newValue) => setTemperature(Number(newValue))}
                      min={0.0}
                      max={1.0}
                      step={0.01}
                      text={
                        "Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive."
                      }
                    />
                    <Slider
                      label="Top P"
                      value={topP}
                      setValue={(newValue) => setTopP(Number(newValue))}
                      min={0.0}
                      max={1.0}
                      step={0.01}
                      text={
                        "Only tokens within the top top_p% probability are considered."
                      }
                    />
                    <Slider
                      label="Repetition Penalty"
                      value={repetitionPenalty}
                      setValue={(newValue) =>
                        setRepetitionPenalty(Number(newValue))
                      }
                      min={1.0}
                      max={2.0}
                      step={0.01}
                      text={
                        "The parameter for repetition penalty. 1.0 means no penalty."
                      }
                    />
                  </div>

                  {loading ? (
                    <button className="loading-button w-[136px] h-[52px] mx-auto">
                      <ImSpinner3 className="text-xl mr-1 animate-spin" />
                      Running
                    </button>
                  ) : initialising ? (
                    <button className="loading-button w-[136px] h-[52px] mx-auto">
                      <ImSpinner3 className="text-xl mr-1 animate-spin" />
                      Initializing
                    </button>
                  ) : (
                    <button
                      disabled={!isActive}
                      className={`${
                        isActive ? "classic-button" : "disabled-button"
                      } w-[136px] h-[52px] mx-auto`}
                      onClick={handleFormSubmit}
                    >
                      Run
                    </button>
                  )}
                  {initialising && (
                    <p className="text-gen-color mt-4 text-[14px] font-[600]">
                      The Model instance is initializing. This will take 1 to 2
                      mins. Once active, we will process your request.
                    </p>
                  )}
                </div>

                <div className="w-1/2 min-h-[538px] p-8 border-l-2">
                  <p className="text-[16px] font-[600] mb-8">Response</p>
                  {response && (
                    <div
                      className="text-base text-gray-700 border border-gray-300 resize-y w-full p-3 bg-white rounded-lg overflow-auto max-h-[400px]"
                      style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                    >
                      <Markdown className="overflow-auto">{response}</Markdown>
                    </div>
                  )}
                </div>
              </Box>
            </Paper>
          </Container>
        </div>
      </div>

      {/* <Footer />
      <GetApiKeyModal open={isGetAPIModalOpen} handleClose={() => setIsGetAPIModalOpen(false)} saveApiKey={saveApiKey} />
      <AddCreditModal open={isOpen} onClose={() => setIsOpen(false)} /> */}
      <Footer className="ml-[90px]" />
      <GetApiKeyModal
        isGetAPIModalOpen={isGetAPIModalOpen}
        setIsGetAPIModalOpen={setIsGetAPIModalOpen}
      />
    </div>
  );
};

export default GetUiT;
