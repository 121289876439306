import React, { useState } from "react";
import { Box, Container, Paper, Typography,Button, Link  } from "@mui/material";
import { SiReact, SiGnubash } from "react-icons/si";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import pythonIcon from "../../assets/python.png";
import { MdContentCopy,MdSave, MdSettings,MdInfoOutline } from "react-icons/md";
import GetApiKeyModal from "../UserProfileActions/GetApiKeyModal";
import { IoCodeSharp, IoDocumentTextOutline } from "react-icons/io5";

const ModelAPI = ({ category, modelData }) => {
  const [tabValue, setTabValue] = useState(0);
  const [isGetAPIModalOpen, setIsGetAPIModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const AiapiURL = process.env.REACT_APP_AI_BASE_URL;
  const modelIp = `${AiapiURL}api/model/${modelData.name}`;
  // console.log(category)

  let pythonCode = "";
  let curlCode = "";
  // let reactCode = "";

  if (category === "Text Generation" || category === "Code Generation" || category === "Reasoning Models") {
    pythonCode = `
import requests
url = "${modelIp}"
headers = {
  "Authorization": "Bearer <PASTE_API_KEY_HERE>",
  "Content-Type": "application/json"
}
data = {
  "prompt": "Write your query here:",
  "max_new_tokens": 150,
  "temperature": 0.7,
  "top_p": 0.9,
  "repetition_penalty": 1.2
}
response = requests.post(url, json=data, headers=headers)
print(response.json())
    `;

    curlCode = `
export API_KEY=<PASTE_API_KEY_HERE>
curl -X POST "${modelIp}" \\
  -H "Authorization:Bearer $API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{"prompt": "Write your query here:", "max_new_tokens": 150, "temperature": 0.7, "top_p": 0.9, "repetition_penalty": 1.2}'
    `;

    
    
  }

  // Define other categories similarly
  else if (category === "Image Generation") {
    pythonCode = `
import requests
url = "${modelIp}"
headers = {
  "Authorization": "Bearer <PASTE_API_KEY_HERE>",
  "Content-Type": "application/json"
}
data = {
  "prompt": "Write your query here:",
  "guidance_scale": 7.6,
  "num_inference_steps": 50,
  "height": 1024,
  "width": 1024
}
response = requests.post(url, json=data, headers=headers)
if response.status_code == 200:
    with open("generated_image.png", "wb") as file:
        file.write(response.content)
    print("Image saved as generated_image.png")
else:
    print("Error:", response.text)
    `;

    curlCode = `
export API_KEY=<PASTE_API_KEY_HERE>
curl -X POST "${modelIp}" \\
  -H "Authorization: Bearer $API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{"prompt": "Write your query here:", "guidance_scale": 7.6, "num_inference_steps": 50, "height": 1024, "width": 1024}' \\
  --output generated_image.png
    `;

   
  }

  else if (category === "Image-Text to Text") {
    pythonCode = `
import requests

API_KEY = "<PASTE_API_KEY_HERE>"
model_ip = "${modelIp}"  # Replace with the actual model IP

url = f"{model_ip}"
headers = {
    "Authorization": f"Bearer {API_KEY}",
    "Content-Type": "application/json",
}

files = {
    "file": open("mango.jpeg", "rb"),
}

data = {
    "prompt": "what this image says",
}

response = requests.post(url, headers=headers, files=files, data=data)

print(response.status_code, response.text)

    `;

    curlCode = `
export API_KEY=<PASTE_API_KEY_HERE>
curl -X POST "${modelIp}" \\
  -H "Authorization:Bearer $API_KEY" \\
  -H "Content-Type: application/json" \\
  --form 'file=@"mango.jpeg"' \\
  --form 'prompt="what this image says"'
    `;

    
  }

  else if (category === "Object Detection") {
    pythonCode = `
import requests

API_KEY = "<PASTE_API_KEY_HERE>"
url = "${modelIp}"  # Replace with actual model URL

headers = {
    "Authorization": f"Bearer {API_KEY}"
}

# Open image file and send as multipart/form-data
files = {
    "image": open("path/to/your/image.jpg", "rb")  # Provide the correct image path
}

response = requests.post(url, headers=headers, files=files)

# Save the output image
if response.status_code == 200:
    with open("output_image.png", "wb") as f:
        f.write(response.content)
    print("Output image saved as output_image.png")
else:
    print("Error:", response.text)


    `;

    curlCode = `
export API_KEY=<PASTE_API_KEY_HERE>
curl -X POST "${modelIp}" \
  -H "Authorization: Bearer $API_KEY" \
  -F "image=@path/to/your/image.jpg" \
  -o output_image.png

    `;

    
  }

  else if (category === "Speech to Text") {
    pythonCode = `
import requests

API_KEY = "<PASTE_API_KEY_HERE>"
model_ip = "${modelIp}"  # Replace with the actual model IP

url = f"{model_ip}"
headers = {
    "Authorization": f"Bearer {API_KEY}",
    "Content-Type": "application/json",
}

files = {"file": open("audio11.mp3", "rb")}

response = requests.post(url, headers=headers, files=files)

print(response.status_code, response.text)

    `;

    curlCode = `
export API_KEY=<PASTE_API_KEY_HERE>
curl -X POST "${modelIp}" \\
  -H "Authorization:Bearer $API_KEY" \\
  -H "Content-Type: application/json" \\
  --form 'file=@"audio11.mp3"'
    `;

    
  }

  else if (category === "Text to 3D") {
    pythonCode = `
import requests
import json

API_KEY = "<PASTE_API_KEY_HERE>"
model_ip = "${modelIp}"

headers = {
    "Authorization": f"Bearer {API_KEY}",
    "Content-Type": "application/json"
}

data = {
    "prompt": "Write your query here",
    "guidance_scale": 15
}

response = requests.post(model_ip, headers=headers, json=data)

if response.status_code == 200:
    with open("generated_html.html", "w", encoding="utf-8") as file:
        file.write(response.text)
    print("File saved as generated_html.html")
else:
    print(f"Error: {response.status_code}, {response.text}")

    `;

    curlCode = `
export API_KEY=<PASTE_API_KEY_HERE>
curl -X POST "${modelIp}" \\
  -H "Authorization:Bearer $API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{"prompt": "Write your query here",
  "guidance_scale": 15}' \\
  -o generated_html.html
    `;

    
  }

  else if (category === "Text To Video") {
    pythonCode = `
import requests
import json

API_KEY = "<PASTE_API_KEY_HERE>"
model_ip = "<MODEL_IP_HERE>"

headers = {
    "Authorization": f"Bearer {API_KEY}",
    "Content-Type": "application/json"
}

data = {
    "prompt": "Write your query here:",
    "num_inference_steps": 8,
    "num_frames": 25
}

response = requests.post(model_ip, headers=headers, json=data)

if response.status_code == 200:
    with open("generated_video.gif", "wb") as file:
        file.write(response.content)
    print("File saved as generated_video.gif")
else:
    print(f"Error: {response.status_code}, {response.text}")

    `;

    curlCode = `
export API_KEY=<PASTE_API_KEY_HERE>
curl -X POST "${modelIp}" \\
  -H "Authorization:Bearer $API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{"prompt": "Write your query here:",
  "num_inference_steps": 8,"num_frames": 25
  }'\\
  -o generated_video.gif
    `;

    
  }



  const codeTabs = [
    {
      label: "Curl",
      icon: <SiGnubash size={26} color="black" />,
      code: curlCode,
      language: "bash",
    },
    {
      label: "Python",
      icon: (
        <img
          src={pythonIcon}
          alt="python icon"
          style={{ width: "24px", height: "24px" }}
        />
      ),
      code: pythonCode,
      language: "python",
    },
    // {
    //   label: "React",
    //   icon: <SiReact size={26} color="#61DAFB" />,
    //   code: reactCode,
    //   language: "javascript",
    // },
  ];

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };
  const handleGetAPIToken = () => {
    setIsGetAPIModalOpen(true);
  };

  return (
    <div className="min-h-[538px] w-full bg-white shadow-md p-8">
      <Container sx={{ maxWidth: { xs: "100%", sm: "1252px" } }}>
        <Paper elevation={3} sx={{ p: 2, mb: 4, bgcolor: "#fff" }}>
          <Box className="flex flex-col sm:flex-row justify-between items-center">
            <Typography>
              You can call this prompt from the API by copying the
              following code into your project.
            </Typography>
          </Box>

          {/* API Key Information */}
          <Box sx={{ mt: 2, mb: 4 }}>
            <Typography variant="h6" className="flex items-center gap-2">
              <MdSettings size={24} /> How to Get Your API Key
            </Typography>
            <Typography>
              To access the API, you need an API key. You can obtain your API key by clicking the button below:
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<IoCodeSharp />}
              onClick={handleGetAPIToken}
              sx={{ mt: 2, mb: 2 }}
            >
              Get API Token
            </Button>
            <Typography>
              Once you have your API key, replace <code>&lt;PASTE_API_KEY_HERE&gt;</code> in the code snippets below.
            </Typography>
          </Box>

          {/* Important Message */}
          <Box sx={{ mt: 2, mb: 4, p: 2, bgcolor: "#f0f4f8", borderRadius: "4px" }}>
            <Typography variant="h6" className="flex items-center gap-2">
              <MdInfoOutline size={24} /> Important Information
            </Typography>
            <Typography>
              For the first time, the API may take up to 5 minutes to get a response because the model has to come online. Once the model is online, you will get an immediate response.
            </Typography>
          </Box>

          {/* Save Response Information */}
          <Box sx={{ mt: 2, mb: 4 }}>
            <Typography variant="h6" className="flex items-center gap-2">
              <MdSave size={24} /> Saving Responses Locally
            </Typography>
            <Typography>
              The provided code snippets include examples of saving API responses to your local machine. For example:
              <ul>
                <li>Python: Responses are saved using <code>open("filename", "wb")</code>.</li>
                <li>Curl: Responses are saved using the <code>-o</code> flag.</li>
              </ul>
            </Typography>
          </Box>

          {/* Code Snippets */}
          {codeTabs.map((tab, index) => (
            <div key={index} className="my-4">
              <Typography variant="h6" className="flex items-center gap-2">
                {tab.icon} {tab.label}
              </Typography>
              <CopyToClipboard text={tab.code} onCopy={handleCopy}>
                <button className="mt-2 mb-4 text-blue-500 flex items-center">
                  <MdContentCopy size={20} /> {copied ? "Copied!" : "Copy"}
                </button>
              </CopyToClipboard>
              <SyntaxHighlighter
                language={tab.language}
                style={materialDark}
                className="rounded-md p-4"
              >
                {tab.code}
              </SyntaxHighlighter>
            </div>
          ))}
        </Paper>
      </Container>
      <GetApiKeyModal
        isGetAPIModalOpen={isGetAPIModalOpen}
        setIsGetAPIModalOpen={setIsGetAPIModalOpen}
      />
    </div>
  );
};

export default ModelAPI;
