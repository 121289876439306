import { TextField } from "@mui/material";
import React, { useState, useContext } from "react";
import { Context } from "../../../Context";
import { ImSpinner3 } from "react-icons/im";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { IoCloseCircleSharp } from "react-icons/io5";
import {
  getInstanceStatus,
  getImageGeneration,
  getStatusLight,
  getInstanceStatusStopper,
} from "../../../services/api";
import Slider from "../../Slider";
import StepsSlider from "../../StepsSlider";
import axiosInstance from '../../../api/axios';
import AddCreditModal from "../../AddCreditModal";

const ImageGeneration = ({ endpoint, isActive, ins_id, modelName,credit }) => {
  const { setModelStatusLight } = useContext(Context);
  const [userQuery, setUserQuery] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialising, setInitialising] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [guidanceScale, setGuidanceScale] = useState(7.6);
  const [numInferenceSteps, setNumInferenceSteps] = useState(50);
  const [numHeightSteps, setNumHeightSteps] = useState(1024);
  const [numWidthSteps, setNumWidthSteps] = useState(1024);
  const [isOpen, setIsOpen] = useState(false);

  // const position = (guidanceScale - 1) / (10 - 1) * 100; // Calculate position based on slider value
  // const numInfPos = (numInferenceSteps - 1) / (100 - 1) * 100;

  const allowedSteps = [64, 256, 512, 768, 1024];

  const checkInstance = async () => {
    setResponse("");
    if (!userQuery) {
      toast.warning("Image Query Required");
      return;
    }
    setInitialising(true);
    setModelStatusLight("initialising");

    try {
        // Step 1: Check for available credits (GET request)
        const rescheck = await axiosInstance.get(`payments/availableCredit/?model-name=${modelName}`);

        if (rescheck.data.data.status) {
            // Step 2: Credit is available, proceed to deduct it (POST request)
            const deductCredit = await axiosInstance.post('payments/availableCredit/', {
                model_name: modelName,
                add: false,
                sub: true,
                amount: 0
            });

            if (deductCredit.data.data.status) {
                // Proceed with the instance status check since credit deduction was successful
                const res = await getInstanceStatus({ instance_id: ins_id });
                console.log(res);

                if (res.status === 200) {
                    setLoading(true);
                    setInitialising(false);

                    try {
                        const responseLight = await getStatusLight({ instance_id: ins_id });
                        setModelStatusLight(responseLight.data.status);
                    } catch (error) {
                        console.log(error.message);
                        await refundCredit(); // Refund credit if something goes wrong
                    }

                    // Trigger handleClick for text generation
                    handleClick(deductCredit);
                }
            } else {
                // Failed to deduct credit, stop further execution
                toast.error(deductCredit.data.message);
                setLoading(false);
                setInitialising(false);
                return false; // Stop further execution
            }
        } else {
            // If no credit, show warning and stop the process
            toast.error(rescheck.data.message);
            setIsOpen(true); // Open AddCreditModal when no credit
            setLoading(false);
            setInitialising(false);
            return false; // Stop further execution
        }
    } catch (error) {
        toast.error("Something Went Wrong,Please Try Again!");
        setLoading(false);
        setInitialising(false);
    }
};

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage("");
    setModalIsOpen(false);
  };

  const handleClick = async (deductCredit) => {
    setResponse("");
    if (!userQuery) {
      toast.warning("Image Query Required");
      return;
    }

    setResponse("");

    try {
      const response = await getImageGeneration(endpoint, {
        text_prompt: userQuery,
        guidance_scale: guidanceScale,
        num_inference_steps: numInferenceSteps,
        height: numHeightSteps,
        width: numWidthSteps,
      });
      const base64Image = response.data.image;
      const imageUrl = `data:image/jpeg;base64, ${base64Image}`;
      setResponse(imageUrl);
      toast.success(deductCredit.data.message);
      if(deductCredit.data.message === "You have used 1 out of 1 free inferencing credits.") {
        infInstanceStatus()
      }
    } catch (error) {
      console.error("Error generating image:", error);
      await refundCredit(); // Refund credit on error
    } finally {
      setLoading(false);
      const res = await getInstanceStatusStopper({ instance_id: ins_id });
      console.log(res);
    }
  };

  // Function to refund credit
  const refundCredit = async () => {
    try {
        await axiosInstance.post('payments/availableCredit/', {
            model_name: modelName,
            add: true,
            sub: false,
            amount: 0
        });
        console.log('Credit refunded successfully');
    } catch (error) {
        console.log('Failed to refund credit:', error.message);
    }
};

const infInstanceStatus = async () => {
  try {
    await axiosInstance.post("payments/updateStatus/", {
      all_free_credits_used: true
    });
    console.log("Credit refunded successfully");
  } catch (error) {
    console.log("Failed to refund credit:", error.message);
  }
};

  const handleSliderChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const closestStep = allowedSteps.reduce((prev, curr) =>
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    );
    setNumHeightSteps(closestStep);
  };

  const handleWidthChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const closestStep = allowedSteps.reduce((prev, curr) =>
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    );
    setNumWidthSteps(closestStep);
  };

  return (
    <div className="flex w-full h-full">
      {/* User Interaction Section */}
      <div className="w-1/2 h-full p-8">
        <p className="text-[16px] font-[600] mb-8">Generate Text To Image</p>
        <p className='text-[15px] font-[500] -mt-6 mb-6'>Running this model will deduct <span className='text-[#008B16] font-[800]'>${credit}</span> / request from your balance. </p>
        <TextField
          className="w-full"
          sx={{ marginBottom: "40px" }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey) {
              e.preventDefault();
              setUserQuery((prevValue) => prevValue + "\n");
            } else if (e.key === "Enter") {
              e.preventDefault();
              setUserQuery(e.target.value);
              checkInstance();
            }
          }}
          value={userQuery}
          id="outlined-multiline-static"
          label="Image Query"
          multiline
          rows={2}
          onChange={(e) => setUserQuery(e.target.value)}
          placeholder="A peaceful lakeside cabin surrounded by tall pine trees under a clear blue sky..."
        />

        <Slider
          label={"Guidance Scale"}
          value={guidanceScale}
          setValue={setGuidanceScale}
          min={1}
          max={10}
          step={0.1}
          text={
            "A higher guidance scale value encourages the model to generate images closely linked to the text prompt at the expense of lower image quality."
          }
        />

        <Slider
          label={"Number Inference Steps"}
          value={numInferenceSteps}
          setValue={setNumInferenceSteps}
          min={1}
          max={100}
          step={1}
          text={
            "The number of denoising steps. More denoising steps usually lead to a higher quality image at the expense of slower inference."
          }
        />

        <StepsSlider
          label={"Height"}
          value={numHeightSteps}
          min={64}
          max={1024}
          handleChange={handleSliderChange}
          text={
            "The height in pixels of the generated image (between 64 and 1024)."
          }
        />
        <StepsSlider
          label={"Width"}
          value={numWidthSteps}
          min={64}
          max={1024}
          handleChange={handleWidthChange}
          text={
            "The width in pixels of the generated image (between 64 and 1024)."
          }
        />

        {loading ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Running
          </button>
        ) : initialising ? (
          <button className="loading-button w-[136px] h-[52px] mx-auto">
            <ImSpinner3 className="text-xl mr-1 animate-spin" />
            Initializing
          </button>
        ) : (
          <button
            disabled={!isActive}
            className={`${
              isActive ? "classic-button" : "disabled-button"
            } w-[136px] h-[52px] mx-auto `}
            onClick={() => checkInstance()}
          >
            Run
          </button>
        )}
        {initialising && (
          <p className="text-gen-color mt-4 text-[14px] font-[600]">
            The Model instance is initializing, This will take 1 to 2 mins. Once
            the instance is active we will process your request
          </p>
        )}
      </div>

      {/* Response Section */}
      <div className="w-1/2 min-h-[538px] p-8 border-l-2 ">
        <p className="text-[16px] font-[600] mb-8">Response</p>
        {response && (
          <img
            src={response}
            alt="Generated"
            className="max-w-sm mx-auto max-h-[400px] rounded-md cursor-pointer"
            onClick={() => openModal(response)}
          />
        )}

        {/* Modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Enlarged Image"
          style={{
            overlay: {
              zIndex: "9999",
            },
            content: {
              width: "80vh",
              maxHeight: "80vh",
              minHeight: "80vh",
              minWidth: "80vh",
              border: "1px gray solid",
            },
          }}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
          <button
            className="absolute -top-3 -right-3 m-4 text-[#e81123] text-3xl"
            onClick={closeModal}
          >
            <IoCloseCircleSharp />
          </button>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Enlarged"
              className="rounded-lg min-h-[80vh]"
            />
          )}
        </Modal>
      </div>
      {/* Add Credit Modal */}
      <AddCreditModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default ImageGeneration;
