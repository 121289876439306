import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  TextField,
  Typography,
  Slider,
} from "@mui/material";
import AddCreditModal from "../AddCreditModal";
import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import axiosInstance from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import nvidiaLogo from "../../assets/nvdia logo.png";

const handleRefreshToken = async () => {
  try {
    await axiosInstance.post("/user/refresh_token");
    return true;
  } catch (error) {
    console.error("Refresh token error:", error);
    return false;
  }
};

const DeployModal = ({ isDeployModalOpen, setIsDeployModalOpen, modelName }) => {
  const [gpuPlans, setGpuPlans] = useState({});
  const [selectedGpuDetails, setSelectedGpuDetails] = useState({
    gpuName: "",
    gpuCount: 1,
    gpuPrice: 0,
    qubridName: "",
  });
  const [autoStop, setAutoStop] = useState(60); // Default auto-stop value
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [isDeploying, setIsDeploying] = useState(false); // Deployment state
  const [isCreditOpen, setIsCreditOpen] = useState(false); // Credit modal state
  const navigate = useNavigate();

  // Fetch GPU plans when the modal opens
  useEffect(() => {
    const fetchGpuPlans = async () => {
      try {
        const response = await axiosInstance.get("/nocode/gpu_plans");
        setGpuPlans(response.data.data);
      } catch (error) {
        console.error("Error fetching GPU plans:", error);
        toast.error("Failed to fetch GPU plans");
      }
    };

    if (isDeployModalOpen) {
      fetchGpuPlans();
    }
  }, [isDeployModalOpen]);

  // Handle GPU selection
  const handleSelectGPU = (gpuName, gpuDetails) => {
    const defaultOption = gpuDetails[0];
    setSelectedGpuDetails({
      gpuName,
      gpuCount: defaultOption.gpu_count,
      gpuPrice: defaultOption.price,
      qubridName: defaultOption.qubrid_name,
    });
  };

  // Handle GPU count slider change
  const handleSliderChange = (event, newValue) => {
    const selectedOption = gpuPlans[selectedGpuDetails.gpuName].find(
      (option) => option.gpu_count === newValue
    );
    if (selectedOption) {
      setSelectedGpuDetails((prev) => ({
        ...prev,
        gpuCount: newValue,
        gpuPrice: selectedOption.price,
        qubridName: selectedOption.qubrid_name,
      }));
    }
  };

  // Calculate total price based on GPU count and price
  const getTotalPrice = () => {
    return (selectedGpuDetails.gpuPrice).toFixed(2);
  };

  // Handle auto-stop input change
  const handleAutoStopChange = (event) => {
    const value = Math.max(1, event.target.value); // Ensure value is at least 1
    setAutoStop(value);
  };

  // Handle deployment
  const handleDeployModel = async () => {
    if (!selectedGpuDetails.gpuName) {
      toast.error("Please select a GPU.");
      return;
    }

    setIsDeploying(true);

    try {
      const payload = {
        model_name: modelName,
        qubrid_name: selectedGpuDetails.qubridName,
        price: selectedGpuDetails.gpuPrice,
        gpu_name: selectedGpuDetails.gpuName,
        number_of_gpu: selectedGpuDetails.gpuCount,
        auto_stop: autoStop,
      };

      const response = await axiosInstance.post(
        "/resources/deploy_tunning_models",
        payload
      );

      if (response && response.data) {
        toast.success(response.data.message);

        if (response.data.message === "Insufficient Credit To Launch Instance") {
          setIsCreditOpen(true);
        } else {
          // Start polling for deployment status
          const uniqueKey = `deploymentIntervalId_${response.data.plan_name}`;
          const intervalId = setInterval(async () => {
            try {
              const statusResponse = await axiosInstance.get(
                `/resources/track-model-deployment-status?plan_name=${response.data.model_name}`
              );
              const status = statusResponse.data.status;

              if (status === "Complete") {
                clearInterval(intervalId);
                localStorage.removeItem(uniqueKey);
                toast.success(`${response.data.model_name} is now available to use!`);
                navigate("/myInstance", { state: { openTab: 2 } });
              }
            } catch (error) {
              console.error("Error fetching status:", error);
              clearInterval(intervalId);
              localStorage.removeItem(uniqueKey);
              toast.error("Error fetching deployment status. Please try again later.");
            }
          }, 60000); // Poll every 60 seconds

          localStorage.setItem(uniqueKey, intervalId);
        }
      }
    } catch (error) {
      console.error("Error deploying model:", error);
      toast.error(error.response?.data?.message || "Failed to deploy model");

      if (error.response?.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          handleDeployModel(); // Retry after refreshing token
        }
      }
    } finally {
      setIsDeploying(false);
      setIsDeployModalOpen(false);
    }
  };

  return (
    <div>
      {isDeployModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Dark Overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
          {/* Modal Content */}
          <div className="relative z-50 w-full max-w-xl mx-auto bg-white rounded-lg shadow-lg">
            {/* Modal Header */}
            <div className="flex items-center justify-between p-5 border-b border-gray-200">
              <h4 className="text-lg font-semibold">Deploy</h4>
              <button
                className="p-1 transition-colors duration-150 ease-in-out rounded-full hover:bg-gray-200"
                onClick={() => setIsDeployModalOpen(false)}
                disabled={isDeploying}
              >
                <MdClose className="w-6 h-6" />
              </button>
            </div>

            {/* Modal Body */}
            <Box className="p-6">
              {isLoading ? (
                <div className="flex justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {/* GPU Selection */}
                  <p className="text-lg font-bold text-purple-600 mb-2">🎛️ GPU Selection</p>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                    {Object.keys(gpuPlans).map((gpuName) => (
                      <div
                        key={gpuName}
                        className={`px-2 border rounded-lg cursor-pointer shadow-sm min-w-fit ${
                          selectedGpuDetails.gpuName === gpuName
                            ? "border-purple-500 bg-purple-50"
                            : "border-gray-200"
                        } hover:border-purple-400`}
                        onClick={() => handleSelectGPU(gpuName, gpuPlans[gpuName])}
                      >
                        <div className="flex items-center space-x-2">
                          <input
                            type="radio"
                            checked={selectedGpuDetails.gpuName === gpuName}
                            onChange={() => handleSelectGPU(gpuName, gpuPlans[gpuName])}
                            className="form-radio text-purple-500 focus:ring-purple-500"
                          />
                          <img src={nvidiaLogo} alt="Nvidia Logo" className="" />
                          <p className="mt-2 pt-2 font-semibold text-sm">{gpuName}</p>
                        </div>
                        <p className="text-sm text-gray-500 my-2 ml-6">
                          from ${gpuPlans[gpuName][0]?.price || 0} / hr
                        </p>
                      </div>
                    ))}
                  </div>

                  {/* GPU Count Selection */}
                  {selectedGpuDetails.gpuName && (
                    <>
                      <p className="text-lg font-bold text-purple-600 mb-2">📊 GPU Count Selection</p>
                      <div className="flex items-center mb-6">
                        <label className="flex items-center text-sm mr-3 font-semibold">
                          GPU Count
                          <FaInfoCircle className="ml-1 text-gray-400" />
                        </label>
                        <Slider
                          value={selectedGpuDetails.gpuCount}
                          onChange={handleSliderChange}
                          min={1}
                          max={Math.max(
                            ...gpuPlans[selectedGpuDetails.gpuName].map(
                              (option) => option.gpu_count
                            )
                          )}
                          step={1}
                          valueLabelDisplay="auto"
                          marks={gpuPlans[selectedGpuDetails.gpuName].map((option) => ({
                            value: option.gpu_count,
                            label: `${option.gpu_count}`,
                          }))}
                          style={{ accentColor: "#8F27C1" }}
                        />
                        <p className="bg-gray-100 text-right font-semibold text-green-600 ml-6 border rounded-md p-2">
                          ${getTotalPrice()} / hr
                        </p>
                      </div>
                    </>
                  )}

                  {/* Auto-stop Configuration */}
                  <p className="text-lg font-bold text-purple-600 mt-4 mb-2">⏳ Auto-stop Configuration</p>
                  <TextField
                    fullWidth
                    label="Auto-stop After (hours)"
                    type="number"
                    value={autoStop}
                    onChange={handleAutoStopChange}
                    inputProps={{ min: 1 }}
                    disabled={isDeploying}
                    sx={{ mb: 2 }}
                  />
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                    <strong>Note:</strong> 💡 Set the number of hours after which the model will automatically stop.
                  </Typography>
                </>
              )}
            </Box>

            {/* Modal Footer */}
            {!isLoading && (
              <div className="flex justify-end p-4 border-t border-gray-200">
                <button
                  className="h-[42px] w-[120px] px-4 py-2 mr-2 text-sm font-bold text-black bg-white border border-black rounded-lg hover:bg-gray-200 hover:border-none"
                  onClick={() => setIsDeployModalOpen(false)}
                  disabled={isDeploying}
                >
                  Cancel
                </button>
                <button
                  className={`px-4 py-2 mr-2 text-sm font-bold rounded-lg h-[42px] w-[120px] transition-colors duration-150 ease-in-out ${
                    isDeploying
                      ? "text-gray-400 bg-gray-200 cursor-not-allowed"
                      : "text-[#8F27C1] bg-[#8F27C126] border border-[#8F27C133] hover:bg-[#8F27C140] hover:text-[#7A1EA8]"
                  }`}
                  onClick={handleDeployModel}
                  disabled={isDeploying || !selectedGpuDetails.gpuName}
                >
                  {isDeploying ? "Deploying..." : "Deploy"}
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Add Credit Modal */}
      <AddCreditModal isOpen={isCreditOpen} setIsOpen={setIsCreditOpen} />
    </div>
  );
};

export default DeployModal;