import React, { useState, useEffect, useRef, useCallback } from "react";
import axiosInstance from "../../api/axios";
import { Box, CircularProgress, Typography, Button, Snackbar } from "@mui/material";
import { useLocation } from "react-router-dom";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import { Link} from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const ContainerLogs = () => {
  const location = useLocation();
  const model = location.state?.model;
  const [logs, setLogs] = useState("");
  const [filteredLogs, setFilteredLogs] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);
  const logRef = useRef(null);

  // Function to filter out unwanted log entries
  const filterLogs = useCallback((logs) => {
    return logs
      .split("\n")
      .filter((log) => !log.match(/\d+\.\d+\.\d+\.\d+ - - \[.*\] \".*GET \/ HTTP\/1\.1.*\" \d+ -/))
      .join("\n");
  }, []);

  const fetchLogs = useCallback(async () => {
    try {
      const response = await axiosInstance.post(`/resources/logs`, {
        // model_name: "amit_testing-1738725000",
        model_name:model.model_name
      });

      if (response.data.logs) {
        const logsData = response.data.logs[Object.keys(response.data.logs)[0]] || "No logs available.";
        setLogs(logsData);
        setFilteredLogs(filterLogs(logsData));
      }
    } catch (err) {
      console.error("Error fetching logs:", err);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [filterLogs]);

  useEffect(() => {
    fetchLogs(); // Fetch logs immediately
    const interval = setInterval(fetchLogs, 3000); // Fetch logs every 3 seconds
    return () => clearInterval(interval);
  }, [fetchLogs]);

  useEffect(() => {
    if (autoScroll && logRef.current) {
      setTimeout(() => {
        logRef.current.scrollTop = logRef.current.scrollHeight;
      }, 100);
    }
  }, [filteredLogs, autoScroll]);

  const copyLogs = () => {
    navigator.clipboard.writeText(filteredLogs);
    setCopySuccess(true);
  };

  return (
    <div className="flex w-full min-h-screen bg-gray-100">
      <Sidebar className="flex-shrink-0 h-full fixed" />
      <div className="flex flex-col w-full ml-[90px]">
        <Navbar className="fixed w-full" />
        <div className="container mx-auto p-6 mt-20">
          {/* Breadcrumbs */}
                      <div className="flex justify-between py-4">
                        <div className="flex items-center">
                        {model?.type === "Deployed model" ? (
                          <Link to="/myInstance?tab=tuned">
                            <Typography
                              variant="h6"
                              component="h2"
                              className="text-xl font-bold text-heading-black hover:text-blue-500"
                            >
                              Deployed Models
                            </Typography>
                          </Link>
                        ) : (
                          <Link to="/modelStudio?tab=huggingFaceModels">
                            <Typography
                              variant="h6"
                              component="h2"
                              className="text-xl font-bold text-heading-black hover:text-blue-500"
                            >
                              Hugging Face Models
                            </Typography>
                          </Link>
                        )}
          
                          <MdKeyboardArrowRight className="text-2xl" />
                          <Link
                            to={`/container-logs`}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="h2"
                              className="text-xl font-bold text-heading-black hover:text-blue-500"
                            >
                              View Detail
                            </Typography>
                          </Link>
                        </div>
                      </div>
          <Box className="bg-white shadow-lg rounded-lg p-6">
            <Typography variant="h6" className="text-lg font-bold mb-4">
              Container Logs
            </Typography>
            {loading ? (
              <Box className="flex justify-center">
                <CircularProgress />
              </Box>
            ) : error ? (
              <Typography variant="body2" className="text-red-500">
                Error fetching logs. Please try again later.
              </Typography>
            ) : (
              <Box
                ref={logRef}
                className="max-h-96 overflow-y-auto text-sm bg-gray-900 text-green-400 p-4 rounded-lg shadow-inner border border-gray-700"
              >
                <pre>
                  {filteredLogs.split("\n").map((log, idx) => (
                    <div key={idx}>{log}</div>
                  ))}
                </pre>
              </Box>
            )}

            <Box className="flex justify-between mt-4">
              {/* <Button variant="contained" color="primary" onClick={copyLogs}>
                Copy Logs
              </Button> */}
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setAutoScroll(!autoScroll)}
              >
                {autoScroll ? "Disable Auto-Scroll" : "Enable Auto-Scroll"}
              </Button>
            </Box>
          </Box>
        </div>
        <Footer className="mt-auto ml-[90px]" />
      </div>
      <Snackbar
        open={copySuccess}
        autoHideDuration={2000}
        onClose={() => setCopySuccess(false)}
        message="Logs copied to clipboard!"
      />
    </div>
  );
};

export default ContainerLogs;